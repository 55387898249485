import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { track } from "utils/tracking";

export default class extends Controller {
  static values = {
    id: String,
    type: String,
    resourceType: String,
    resourceId: String,
    locale: String,
    dueDate: String,
    previewPath: String,
    previewable: Boolean,
    actionsUrl: String,
    translations: Object,
    employeeId: String,
    mobility: String,
  };

  previewModal = document.getElementById("previewModal");
  previewModalContent = document.getElementById("previewModalContent");
  editModal = document.getElementById("editModal");
  editModalContent = document.getElementById("editModalContent");
  deletionModal = document.getElementById("deletionModal");

  askDeletion(event) {
    event.preventDefault();
    event.stopPropagation();

    const freezeDelete = (event) => {
      this._delete(event);
    };
    const setDeletionTrigger = (event) => {
      this.deletionModal
        .querySelector(".deletion__button")
        .addEventListener("click", freezeDelete);
    };
    const removeDeletionTrigger = (event) => {
      this.deletionModal
        .querySelector(".deletion__button")
        .removeEventListener("click", freezeDelete);
    };
    const customEvent = new CustomEvent("modalbehavior.show", {
      detail: {
        showCallback: setDeletionTrigger,
        hideCallback: removeDeletionTrigger,
      },
    });
    this.deletionModal.dispatchEvent(customEvent);
  }

  _delete(event) {
    event.preventDefault();
    event.stopPropagation();

    fetch(
      `${this.actionsUrlValue}/${this.idValue}?action_type=${this.typeValue}`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-csrf-token": csrfToken(),
        },
      },
    ).then(() => {
      $(window).trigger("fetch-actions");
      const customEvent = new CustomEvent("modalbehavior.hide");
      this.deletionModal.dispatchEvent(customEvent);
      flash(this.translationsValue.action_deleted, "notice");
    });
  }

  async edit(event) {
    event.preventDefault();
    event.stopPropagation();

    try {
      const response = await fetch(
        `${this.actionsUrlValue}/${this.idValue}/edit?action_type=${this.typeValue}`,
        { method: "GET", headers: { "content-type": "text/html" } },
      );

      if (response.ok) {
        // Fill edit modal with content
        const modalContent = await response.text();
        this.editModalContent.innerHTML = modalContent;
        // Open edit modal
        const customEvent = new CustomEvent("modalbehavior.show");
        this.editModal.dispatchEvent(customEvent);
        $(this.previewModal).modal("hide");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  preview(event) {
    event.preventDefault();

    if (!this.previewableValue) {
      return;
    }

    $(this.previewModal).modal("show");

    this.previewModalContent.innerHTML = `
      <div class="text-center h-100 d-flex align-items-center justify-content-center">
        <i class="fad fa-spinner text-gray text-40px rotating"></i>
      <div>
    `;

    const params = new URLSearchParams({
      locale: this.localeValue,
      class_name: this.resourceTypeValue,
      origin: "track_creation",
      action_id: this.idValue,
    });

    fetch(`${this.previewPathValue}?${params.toString()}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.small_content) {
          this.previewModalContent.classList.add("modal-small-content");
        } else {
          this.previewModalContent.classList.remove("modal-small-content");
        }

        this.previewModalContent.innerHTML = data.content;

        $(this.previewModalContent)
          .find('[data-toggle="modal"]')
          .on("click", () => {
            this.previewModalContent.innerHTML = "";
            this.previewModalContent.classList.remove("modal-small-content");
          });

        if (this.previewModalContent.querySelector('iframe[title="scorm"]')) {
          this._connectScorm(this.previewModalContent);
        }

        track("Preview action in track flow", {
          mobility: this.mobilityValue,
          employee_id: this.employeeIdValue,
          action_type: this.typeValue,
          action_id: this.idValue,
          details: "track_new",
        });
      });
  }

  _connectScorm(modal) {
    const iframe = modal.querySelector('iframe[title="scorm"]');
    // const modalContent = modal.querySelector(".modal-content-segment");
    iframe.classList.add("d-none");

    const placeholderDiv = document.createElement("div");
    placeholderDiv.classList.add("rotating", "fad", "fa-spinner", "text-80px");
    iframe.parentNode.insertBefore(placeholderDiv, iframe);

    setTimeout(() => {
      placeholderDiv.remove();
      iframe.classList.remove("d-none");
    }, 2000);
    iframe.addEventListener("load", () => {
      let iframeWindow = iframe.contentWindow;
      iframeWindow.postMessage(
        {
          username: iframe.dataset.username,
          password: iframe.dataset.password,
        },
        iframe.dataset.baseUrl,
      );
    });
  }
}
