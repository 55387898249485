import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    "addFile",
    "attachFile",
    "attachUrl",
    "attachFileTab",
    "attachUrlTab",
    "file",
    "fileDisplay",
    "fileDisplayName",
    "form",
    "deleteFile",
    "url",
  ];

  static classes = [
    "tabEnabled",
    "tabDisabled",
    "buttonEnabled",
    "buttonDisabled",
  ];

  static values = {
    actionsUrl: String,
  };

  validate() {
    if (this.urlTarget.checkValidity()) {
      this.submitButtonTargets.forEach((submitButtonTarget) => {
        submitButtonTarget.disabled = false;
        submitButtonTarget.classList.add(...this.buttonEnabledClasses);
        submitButtonTarget.classList.remove(...this.buttonDisabledClasses);
      });
    } else {
      this.submitButtonTargets.forEach((submitButtonTarget) => {
        submitButtonTarget.disabled = true;
        submitButtonTarget.classList.remove(...this.buttonEnabledClasses);
        submitButtonTarget.classList.add(...this.buttonDisabledClasses);
      });
    }
  }

  switchAddFile(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.addFileTarget.hidden = false;
      this.attachFileTarget.hidden = false;
      this.attachUrlTarget.hidden = true;
      this.attachFileTabTarget.classList.add(...this.tabEnabledClasses);
      this.attachFileTabTarget.classList.remove(...this.tabDisabledClasses);
      this.attachUrlTabTarget.classList.remove(...this.tabEnabledClasses);
      this.attachUrlTabTarget.classList.add(...this.tabDisabledClasses);
    } else {
      this.addFileTarget.hidden = true;
      this.attachFileTarget.hidden = true;
      this.attachUrlTarget.hidden = true;
    }
  }

  chooseAttachFile(event) {
    event.preventDefault();

    this.attachFileTarget.hidden = false;
    this.attachUrlTarget.hidden = true;

    this.attachFileTabTarget.classList.add(...this.tabEnabledClasses);
    this.attachFileTabTarget.classList.remove(...this.tabDisabledClasses);
    this.attachUrlTabTarget.classList.remove(...this.tabEnabledClasses);
    this.attachUrlTabTarget.classList.add(...this.tabDisabledClasses);
  }

  chooseAttachUrl(event) {
    event.preventDefault();

    this.attachFileTarget.hidden = true;
    this.attachUrlTarget.hidden = false;

    this.attachFileTabTarget.classList.remove(...this.tabEnabledClasses);
    this.attachFileTabTarget.classList.add(...this.tabDisabledClasses);
    this.attachUrlTabTarget.classList.add(...this.tabEnabledClasses);
    this.attachUrlTabTarget.classList.remove(...this.tabDisabledClasses);
  }

  addFile(event) {
    if (this.fileTarget.files[0]) {
      this.fileDisplayTarget.hidden = false;
      this.fileDisplayNameTarget.hidden = false;
      this.fileDisplayNameTarget.innerText = this.fileTarget.files[0].name;
      this.deleteFileTarget.value = "false";
    } else {
      this.fileDisplayTarget.hidden = true;
      this.fileDisplayNameTarget.hidden = true;
      this.fileDisplayNameTarget.innerText = "";
      this.deleteFileTarget.value = "true";
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.fileTarget.value = "";
    this.fileDisplayTarget.hidden = true;
    this.fileDisplayNameTarget.hidden = true;
    this.fileDisplayNameTarget.innerText = "";
    this.deleteFileTarget.value = "true";
  }
}
