import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { csrfToken } from "utils/csrf_token";
import { track } from "utils/tracking";

export default class extends Controller {
  static targets = [
    "hidden",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
    "modalContainer",
    "modalContent",
    "reassignDescription",
    "reassignLabel",
    "notReassign",
  ];

  static values = {
    currentEmployeeId: Number,
    currentEmployeeFullName: String,
    autocompleteUrl: String,
    autocompleteParams: Object,
    isPristine: { type: Boolean, default: true },
    employeeId: String,
    reassign: Boolean,
    reassignDescription: String,
    reassignLabel: String,
    reassignUrl: String,
    implication: String,
    defaultId: String,
    defaultName: String,
    actorsUrl: String,
    trackMobility: String,
    trackEmployeeId: String,
  };

  associate(event) {
    event.preventDefault();

    this._changeActor({
      id: this.currentEmployeeIdValue,
      name: this.currentEmployeeFullNameValue,
    });
  }

  async search() {
    const query = this.isPristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({
      ...this.autocompleteParamsValue,
      query,
      excluded_ids: this.employeeIdValue,
    });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.employees
      .map((actor) =>
        Object.entries(actor).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  touch() {
    this.isPristineValue = false;
  }

  reset() {
    if (!this.isPristineValue) {
      this.hiddenTarget.value = "";
      this.inputTarget.value = "";
    }
  }

  pick(event) {
    event.preventDefault();

    const option = event.target.closest(".input-select__option");

    this._changeActor({ id: option.dataset.id, name: option.dataset.fullName });
    this.dropdownTarget.hidden = true;
    this.isPristineValue = true;
    this._workeloBlur(this.inputTarget);
  }

  hideModalIfOutside(event) {
    if (
      this.modalContainerTarget.contains(event.target) &&
      !this.modalContentTarget.contains(event.target)
    ) {
      this._hideModal();
    }
  }

  cancelReassign(event) {
    event.preventDefault();

    this._hideModal();
  }

  hideModal(event) {
    event.preventDefault();

    this._hideModal();
  }

  confirmReassign(event) {
    event.preventDefault();

    track("Update stakeholder in track", {
      mobility: this.trackMobilityValue,
      employee_id: this.trackEmployeeIdValue,
      stakeholder_id: this.newActorIdValue,
      role: this.implicationValue,
      "keep_action_owner?": this.notReassignTarget.checked,
    })

    fetch(this.reassignUrlValue, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "x-csrf-token": csrfToken(),
      },
      body: JSON.stringify({
        actor: {
          previous_actor_id: this.oldActorIdValue,
          new_actor_id: this.newActorIdValue,
          reassign: !this.notReassignTarget.checked,
          implication: this.implicationValue,
        },
      }),
    }).then(() => {
      window.location = this.actorsUrlValue;
    });
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }

  _hideModal() {
    this.oldActorIdValue = "";
    this.newActorIdValue = "";
    this.oldActorNameValue = "";
    this.newActorNameValue = "";
    this.reassignDescriptionTarget.innerText = "";
    this.reassignLabelTarget.innerText = "";
    this.notReassignTarget.checked = false;
    this.modalContentTarget.hidden = true;
    this.modalContainerTarget.hidden = true;
  }

  _changeActor({ id, name }) {
    if (this.reassignValue && this.defaultIdValue && this.defaultNameValue) {
      this.oldActorIdValue = this.defaultIdValue;
      this.oldActorNameValue = this.defaultNameValue;
      this.newActorIdValue = id;
      this.newActorNameValue = name;
      (this.reassignDescriptionTarget.innerText = Object.entries(
        this._names(),
      ).reduce(
        (result, [key, value]) => result.replaceAll(`{{${key}}}`, value),
        this.reassignDescriptionValue,
      )),
        (this.reassignLabelTarget.innerText = Object.entries(
          this._names(),
        ).reduce(
          (result, [key, value]) => result.replaceAll(`{{${key}}}`, value),
          this.reassignLabelValue,
        )),
        (this.modalContainerTarget.hidden = false);
      this.modalContentTarget.hidden = false;
    } else {
      this.hiddenTarget.value = id;
      this.inputTarget.value = name;

      track("Update stakeholder in track", {
        mobility: this.trackMobilityValue,
        employee_id: this.trackEmployeeIdValue,
        stakeholder_id: id,
        role: this.implicationValue,
      })
    }
  }

  _names() {
    return {
      previous_name: this.oldActorNameValue,
      new_name: this.newActorNameValue,
    };
  }
}
