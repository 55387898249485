import { Controller } from "@hotwired/stimulus";
import { scrollToElement } from "components/scrolling";
import { useClickOutside } from 'stimulus-use';

// To open with highlight, you need to define 2 attributes of "conditional-logic-sidebar" :
//    - data-highlight-id :   id of the target
//    - data-highlight-type : type of the target you want highlight
//                            conditionField   => all logic where field is as condition
//                            conditionalLogic => this specific logic

export default class extends Controller {
  connect() {
    useClickOutside(this);
    let observer = new MutationObserver(this.observeAttributes.bind(this));
    observer.observe(this.element, { attributes: true });
  }

  observeAttributes(mutationsList) {
    for(let mutation of mutationsList) {
      let attributeName = mutation.attributeName;
      if (attributeName == "data-highlight-id") {
        this.removeHightlights();
        if (this.element.dataset.highlightId) { this.highlight(); }
        this.show();
      }
    }
  }

  clickOutside(event) {
    if (!this.clickOnModal(event)) {  this.hide() }
  }

  hide() {
    this.element.classList.remove('extended');
  }

  show() {
    this.element.classList.add('extended');
  }

  highlight() {
    this.element.dataset.highlightType == 'conditionField' ? this.highlightByConditionField() : this.highlightByConditionalLogic();
    scrollToElement(`[conditional-logic-content].border-color-blue`, 200, '#conditional-logic-sidebar-content');
  }

  highlightByConditionField() {
    document.querySelectorAll(`.conditional-logic [logic-condition][data-logic-conditions-field-value="${this.element.dataset.highlightId}"]`).forEach(el => {
      el.closest('[conditional-logic-content]').classList.add('border-color-blue');
    })
  }

  highlightByConditionalLogic() {
    document.querySelector(`.conditional-logic[data-conditional-logics-id-value="${this.element.dataset.highlightId}"] [conditional-logic-content]`).classList.add('border-color-blue');
  }

  removeHightlights() {
    document.querySelectorAll('.conditional-logic .border-color-blue').forEach(el => el.classList.remove('border-color-blue'));
  }

  clickOnModal(event) {
    let clickOnModal;

    // set clickOnModal as true if the event target is or is contained in tabulator-row
    const path = event.path || (event.composedPath && event.composedPath())
    path.forEach(el => {
      if ((el.classList) && el.classList.contains('modal')) {
        return clickOnModal = true
      };
    });

    return clickOnModal;
  }
}
