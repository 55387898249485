import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = [
    "employeeSelection",
    "employeeForm",
    "input",
    "template",
    "dropdown",
    "none",
    "id",
    "firstName",
    "lastName",
    "email",
    "previousJobPosition",
    "newJobPosition",
    "previousUnitOrganizationName",
    "newUnitOrganizationName",
    "newUnitOrganizationId",
    "identificationId",
    "identificationResourceId",
    "identificationValue",
    "language",
    "languageLabel",
    "employeeSelectionHidden",
    "employeeSelectionShown",
    "subtitle",
    "test",
    "link",
    "testContainer",
    "error",
  ];

  static values = {
    autocompleteUrl: String,
    isPristine: { type: Boolean, default: true },
    mobility: String,
  };

  connect() {
    this.search = debounce(this.search.bind(this), 100);
  }

  showEmployeeForm(event) {
    event.preventDefault();

    this.employeeSelectionTarget.hidden = true;
    this.employeeFormTarget.hidden = false;
    this.employeeSelectionHiddenTargets.forEach(
      (element) => (element.hidden = false),
    );
    this.employeeSelectionShownTargets.forEach(
      (element) => (element.hidden = true),
    );
  }

  async search() {
    const query = this.isPristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({ query });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.employees
      .map((employee) =>
        Object.entries({ ...employee, json: JSON.stringify(employee) }).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
    }
  }

  touch() {
    this.isPristineValue = false;
    this.errorTarget.hidden = true;
  }

  reset() {
    if (!this.isPristineValue) {
      this.hiddenTarget.value = "";
      this.inputTarget.value = "";
    }
  }

  pick(event) {
    event.preventDefault();

    const option = event.target.closest(".input-select__option");
    const employee = JSON.parse(option.dataset.json);

    if (employee.ongoing_tracks_count > 0) {
      this.inputTarget.value = option.dataset.fullName;
      this.dropdownTarget.hidden = true;
      this.isPristineValue = true;
      this.errorTarget.hidden = false;
      return;
    }

    this.inputTarget.value = option.dataset.fullName;
    this.errorTarget.hidden = true;
    this.dropdownTarget.hidden = true;
    this.isPristineValue = true;
    this.testContainerTarget.hidden = true;
    this.employeeSelectionTarget.hidden = true;
    this.employeeFormTarget.hidden = false;
    this.emailTarget.disabled = true;
    this.employeeSelectionHiddenTargets.forEach((element) => {
      if (
        !element.dataset.mobility ||
        element.dataset.mobility === this.mobilityValue
      ) {
        element.hidden = true;
      }
    });
    this.employeeSelectionShownTargets.forEach((element) => {
      if (
        !element.dataset.mobility ||
        element.dataset.mobility === this.mobilityValue
      ) {
        element.hidden = false;
      }
    });
    this.testTarget.disabled = true;
    this.emailTarget.disabled = true;
    this.emailTarget.classList.add("bg-grey-200", "text-grey-600");
    this.emailTarget.classList.remove("text-grey-800");

    this.idTarget.value = employee.id;
    this.firstNameTarget.value = employee.first_name;
    this.lastNameTarget.value = employee.last_name;
    this.emailTarget.value = employee.email;
    this.previousJobPositionTarget.value = employee.job_position;
    this.newJobPositionTarget.value = employee.job_position;
    this.newUnitOrganizationIdTarget.value = employee.unit_organization_id;
    this.previousUnitOrganizationNameTarget.value =
      employee.unit_organization_name;
    this.newUnitOrganizationNameTarget.value = employee.unit_organization_name;
    this.languageTarget.value = employee.language;
    this.languageLabelTarget.value = employee.language_label;
    this.subtitleTarget.innerText = `${employee.first_name} ${employee.last_name}`;

    this.linkTarget.href = employee.url;

    employee.identifications.forEach((identification, index) => {
      this._setIdentificationTarget({
        identification,
        targets: this.identificationIdTargets,
        value: identification.id,
      });

      this._setIdentificationTarget({
        identification,
        targets: this.identificationResourceIdTargets,
        value: identification.identification_resource_id,
      });

      this._setIdentificationTarget({
        identification,
        targets: this.identificationValueTargets,
        value: identification.value,
      });
    });
  }

  _setIdentificationTarget({ identification, value, targets }) {
    targets.forEach((target) => {
      const targetResourceId = (
        target.dataset.identificationResourceId || ""
      ).toString();

      const identificationResourceId = (
        identification.identification_resource_id || ""
      ).toString();

      if (
        targetResourceId &&
        identificationResourceId &&
        targetResourceId === identificationResourceId
      ) {
        target.value = value;
      }
    });
  }
}
