import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = [
    "hidden",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
    "list",
    "itemTemplate",
    "employee",
  ];

  static values = {
    autocompleteUrl: String,
    autocompleteParams: Object,
    excludedIds: String,
    isPristine: { type: Boolean, default: true },
  };

  connect() {
    $(this.element).on("reset", () => {
      this.listTarget.innerHTML = "";
      this.hiddenTarget.value = this._employeeIds().join(",");
      this.inputTarget.value = "";
      this.dropdownTarget.innerHTML = "";
      this.dropdownTarget.hidden = true;
      this.isPristineValue = true;
    });

    $(this.element).on("add", (_, data) => {
      if (data?.id && !this._employeeIds().includes(data.id)) {
        const html = Object.entries(data).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.itemTemplateTarget.innerHTML,
        );

        this.listTarget.insertAdjacentHTML("beforeend", html);

        let ids = (this.hiddenTarget.value || "").split(",");
        ids.push(data.id);
        this.hiddenTarget.value = [...new Set(ids)].filter(Boolean).join(",");
        this.inputTarget.value = "";
        this.dropdownTarget.hidden = true;
        this.isPristineValue = true;
      }
    });

    this.hiddenTarget.value = this._employeeIds().join(",");
    this.search = debounce(this.search.bind(this), 100);
  }

  async search() {
    const query = this.isPristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({
      ...this.autocompleteParamsValue,
      ...this._excludedIdsParams(),
      query,
    });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.employees
      .map((employee) =>
        Object.entries(employee).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
    }
  }

  touch() {
    this.isPristineValue = false;
  }

  reset() {
    if (!this.isPristineValue) {
      this.hiddenTarget.value = this._employeeIds().join(",");
      this.inputTarget.value = "";
    }
  }

  pick(event) {
    event.preventDefault();

    const employeeIds = this._employeeIds();
    const option = event.target.closest(".input-select__option");
    const id = option.dataset.id;

    if (employeeIds.includes(id)) {
      flash("Employee déjà ajouté", "alert");
    } else {
      const html = Object.entries(option.dataset).reduce(
        (result, [key, value]) =>
          result.replaceAll(`{{${key}}}`, escapeHtml(value)),
        this.itemTemplateTarget.innerHTML,
      );

      this.listTarget.insertAdjacentHTML("beforeend", html);

      let ids = (this.hiddenTarget.value || "").split(",");
      ids.push(id);
      this.hiddenTarget.value = [...new Set(ids)].filter(Boolean).join(",");
      this.inputTarget.value = "";
      this.dropdownTarget.hidden = true;
      this.isPristineValue = true;
    }
  }

  removeItem(event) {
    event.preventDefault();

    const employeeItem = event.target.closest(
      "[data-desktop--actor--track--input-employees-target='employee']",
    );

    let ids = (this.hiddenTarget.value || "").split(",");
    ids = ids.filter((el) => el !== employeeItem.dataset.id);
    this.hiddenTarget.value = [...new Set(ids)].filter(Boolean).join(",");

    employeeItem.remove();
  }

  _employeeIds() {
    return this.employeeTargets.map((el) => el.dataset.id);
  }

  _excludedIdsParams() {
    let excludedIds = this._employeeIds();
    if (this.hasExcludedIdsValue) {
      excludedIds = excludedIds.concat(this.excludedIdsValue.split(","));
    }

    return { excluded_ids: excludedIds };
  }
}
