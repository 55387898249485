// app/javascript/controllers/drag_drop_controller.js
import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static values = {
    baseUrl: String,
    positionUrl: String,
    errorMessage: String,
  };
  static targets = ["item", "section"];

  initSortable() {
    this.sectionTargets.forEach(this._initSortableFor.bind(this));
  }

  _initSortableFor(section) {
    const itemList = section.querySelector(".dragdrop__items");
    Sortable.create(itemList, {
      group: "shared",
      animation: 150,
      handle: ".dragdrop__handle",
      chosenClass: "dragdrop__chosen",
      ghostClass: "dragdrop__ghost",
      dragClass: "dragdrop__drag",
      onStart: this._initScrolling.bind(this),
      onEnd: this._handleDrop.bind(this),
    });
  }

  _handleDrop(event) {
    this._stopScrolling(event);
    this._updateAction(event);
  }

  _updateAction(event) {
    const action = event.item;
    const url = `${this.baseUrlValue}/update_due_date`.replace(
      "actionId",
      action.dataset["desktop-Actor-Track-ActionIdValue"],
    );
    const actionType = action.dataset["desktop-Actor-Track-ActionTypeValue"];
    const dueDate =
      event.to.parentElement.querySelector("[data-date").dataset.date;

    $.ajax({
      url: url,
      type: "PATCH",
      dataType: "script",
      data: {
        action_type: actionType,
        due_date: dueDate,
      },
      error: (_error) => {
        this._moveActionToOriginalSection(event);
        flash(this.errorMessageValue, "alert");
      },
      success: (_response) => {
        this._updatePositions();
      },
    });
  }

  _updatePositions() {
    let actions = this.itemTargets.map((item) => {
      return {
        action_id: item.dataset["desktop-Actor-Track-ActionIdValue"],
        action_type: item.dataset["desktop-Actor-Track-ActionTypeValue"],
      };
    });

    $.ajax({
      url: this.positionUrlValue,
      type: "PATCH",
      dataType: "script",
      data: {
        actions: JSON.stringify(actions),
      },
    });
  }

  _initScrolling(_event) {
    this.scrolling = this._scroll.bind(this);
    document
      .getElementById("page-content")
      .addEventListener("dragover", this.scrolling);
  }

  _stopScrolling(_event) {
    document
      .getElementById("page-content")
      .removeEventListener("dragover", this.scrolling);
    this.scrolling = null;
  }

  _scroll(event) {
    let verticalMousePosition = event.clientY;
    let thresholdTop = 300;
    let thresholdBottom = window.innerHeight - 150;

    if (verticalMousePosition < thresholdTop) {
      debounce(document.getElementById("page-content").scrollBy(0, -2));
    } else if (verticalMousePosition > thresholdBottom) {
      debounce(document.getElementById("page-content").scrollBy(0, 2));
    }
  }

  _moveActionToOriginalSection(event) {
    const originalSection = event.from;
    const originalIndex = event.oldIndex;
    const action = event.item;

    originalSection.insertBefore(
      action,
      originalSection.children[originalIndex],
    );
    action.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  // TODO : P2 - drag and drop to create a new week

  // // dragover the end section and display a placeholder of new date
  // dragOver(event) {
  //   event.preventDefault();
  //   displayNewDatePlaceholder(event.currentTarget.closest('.dragdrop__end_section'));
  //   document.querySelector('.dragdrop__ghost').classList.add('d-none');
  // }

  // // dragleave the end section and hide the placeholder of new date
  // dragLeave(event) {
  //   event.preventDefault();
  //   hideNewDatePlaceholderOf(event.currentTarget.closest('.dragdrop__end_section'));
  //   document.querySelector('.dragdrop__ghost').classList.remove('d-none');
  // }
}

// const displayNewDatePlaceholder = (endSection) => {
//   if (endSection) {
//     endSection.firstElementChild?.classList?.remove("invisible");
//     endSection.classList?.add("height-72px");
//     endSection.style.padding = "10px 0";
//   }
// }

// const hideNewDatePlaceholderOf = (endSection) => {
//   if (endSection) {
//     endSection.firstElementChild?.classList?.add("invisible");
//     endSection.classList?.remove("height-72px");
//     endSection.style.padding = "0";
//   }
// }
