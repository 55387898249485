import { Controller } from "@hotwired/stimulus";
import { debounce } from "utils/debounce";
import { escapeHtml } from "utils/escapeHtml";

const MAX_ACTORS_HEIGHT = 42;

export default class extends Controller {
  static targets = [
    "actors",
    "actor",
    "more",
    "regularActors",
    "moreActors",
    "moreActor",
    "moreCount",
    "regularActorTemplate",
    "moreActorTemplate",
  ];

  static classes = ["actorSelected"];

  static values = {
    actions: Array,
  };

  connect() {
    this.resize = debounce(this.resize.bind(this), 50);
    $(window).on("fetched-actions", (_, data) => {
      this.actionsValue = data.actions;
      this.actorIdValueChanged();
    });
  }

  resize() {
    this.actorIdValueChanged();
  }

  changeActor(event) {
    event.preventDefault();

    this.element.dataset.actorId = event.target.closest(
      ".actor, .actor__more__item",
    ).dataset.id;

    $("#page-content").scrollTop(0);

    $(window).trigger("change-actor");

    this.actorIdValueChanged();
  }

  actorIdValueChanged() {
    this.regularActorsTarget.innerHTML = this._actors()
      .map((actor) =>
        Object.entries(actor).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.regularActorTemplateTarget.innerHTML,
        ),
      )
      .join("");

    this.moreActorsTarget.innerHTML = this._actors()
      .map((actor) =>
        Object.entries(actor).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.moreActorTemplateTarget.innerHTML,
        ),
      )
      .join("");

    const moreActorIds = [];

    this.actorTargets.forEach((actorTarget) => (actorTarget.hidden = true));

    this.actorTargets.forEach((actorTarget) => {
      actorTarget.hidden = false;

      const computedStyle = window.getComputedStyle(this.actorsTarget);

      if (parseInt(computedStyle.height, 10) > MAX_ACTORS_HEIGHT) {
        actorTarget.hidden = true;
        moreActorIds.push(actorTarget.dataset.id);
      }
    });

    this.moreActorTargets.forEach((moreActorTarget) => {
      if (moreActorIds.includes(moreActorTarget.dataset.id)) {
        moreActorTarget.hidden = false;
      } else {
        moreActorTarget.hidden = true;
      }
    });

    if (moreActorIds.length) {
      this.moreCountTarget.innerText =
        this._translations().more_actors.replaceAll(
          "%{count}",
          moreActorIds.length,
        );
      this.moreCountTarget.hidden = false;
    } else {
      this.moreCountTarget.innerText = "";
      this.moreCountTarget.hidden = true;
    }

    this.actorTargets.forEach((actorTarget) => {
      if (
        this.element.dataset.actorId
          ? actorTarget.dataset.id === this.element.dataset.actorId
          : !actorTarget.dataset.id
      ) {
        actorTarget.classList.add("actor--selected");
      } else {
        actorTarget.classList.remove("actor--selected");
      }
    });
  }

  _translations() {
    return JSON.parse(this.element.dataset.translations);
  }

  _actors() {
    return [
      this._actorAll(),
      ...this._notDefinedActor(),
      ...[
        ...new Set(
          this.actionsValue
            .flatMap((week_group) => week_group.actions_grouped)
            .flatMap((day_group) => day_group.actions)
            .flatMap((action) => action.actors)
            .map((actor) => JSON.stringify(actor)),
        ),
      ]
        .map((actor) => JSON.parse(actor))
        .sort(
          (actor_before, actor_after) =>
            actor_before.implications_order - actor_after.implications_order,
        ),
    ];
  }

  _hasActorNotDefined() {
    return !!this.actionsValue
      .flatMap((week_group) => week_group.actions_grouped)
      .flatMap((day_group) => day_group.actions)
      .some((action) => action.actors.length === 0);
  }

  _actorAll() {
    return {
      id: "all",
      full_name: this._translations().actor_all,
      implications: "",
      implications_hidden: "hidden",
    };
  }

  _notDefinedActor() {
    if (this._hasActorNotDefined()) {
      return [
        {
          id: null,
          full_name: this._translations().actor_not_provided,
          implications: "",
          implications_hidden: "hidden",
        },
      ];
    } else {
      return [];
    }
  }
}
