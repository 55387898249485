// Example :
//   <div data-controller="utils--radio-button-contents">
//     <div>
//       <input type="radio" name="Option A" value="true" data-utils--radio-button-contents-target='radioButton' checked>
//       <div class="d-inline-flex">
//         <div class="d-inline-flex flex-column" data-utils--radio-button-contents-target='content' for="Option A" target-value="true">
//           Content to display if radio_button A-true is checked
//         </div>
//       </div>
//     </div>
//     <div>
//       <input type="radio" name="Option A" value="false" data-utils--radio-button-contents-target='radioButton' checked>
//       <div class="d-inline-flex">
//         <div class="d-inline-flex flex-column" data-utils--radio-button-contents-target='content' for="Option A" target-value="false">
//           Content to display if radio_button B-false is checked
//         </div>
//       </div>
//     </div>
//     <div>
//       <input type="radio" name="Option B" value="true" data-utils--radio-button-contents-target='radioButton' checked>
//       <div class="d-inline-flex">
//         <div class="d-inline-flex flex-column" data-utils--radio-button-contents-target='content' for="Option B" target-value="true">
//           Content to display if radio_button B-true is checked
//         </div>
//       </div>
//     </div>
//     <div>
//       <input type="radio" name="Option B" value="false" data-utils--radio-button-contents-target='radioButton' checked>
//       <div class="d-inline-flex">
//         <div class="d-inline-flex flex-column" data-utils--radio-button-contents-target='content' for="Option B" target-value="false">
//           Content to display if radio_button B-false is checked
//         </div>
//       </div>
//     </div>
//   </div>


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioButton", "content"]

  connect() {
    this.radioButtonTargets.forEach(radioButton => {
      radioButton.addEventListener('change', () => { this.updateContentDisplay(radioButton); })
    })
    this.radioButtonTargets.filter(radioButton => radioButton.checked)
                           .forEach(radioButton => this.updateContentDisplay(radioButton));
  }

  updateContentDisplay(radioButton) {
    this.contentTargets.filter(content => hasSameNameInputFor(content, radioButton))
                       .forEach(content => { this.toggleHiddenClass(radioButton, content)})
  }

  toggleHiddenClass(radioButton, content) {
    if (radioButton.checked && hasSameTargetInputFor(content, radioButton)) {
      // Display the content if its radioButton is checked
      content.classList.remove('hidden')
    } else {
      content.classList.add('hidden');
    }
  }
}

const hasSameNameInputFor = (content, rabdioButton) => {
  let contentFor = content.getAttribute('for')
  let radioButtonName = rabdioButton.getAttribute('name')

  return contentFor == radioButtonName
}

const hasSameTargetInputFor = (content, rabdioButton) => {
  let contentValue = content.getAttribute('target-value')
  let radioButtonValue = rabdioButton.getAttribute('value')

  return contentValue == radioButtonValue
}
