import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  call(event) {
    event.preventDefault();
    fetch(this.element.href, {
      method: this.element.dataset.method,
      headers: { 'X-CSRF-Token': csrfToken() }
    });
  }
}
