import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { escapeHtml } from "utils/escapeHtml";
import { track } from "utils/tracking";

export default class extends Controller {
  static targets = [
    "input",
    "allTemplates",
    "noSelectedTemplates",
    "selectedTemplatesContainer",
    "selectedTemplates",
    "templateAdd",
    "templateRemove",
    "templatesCount",
    "templateAssignments",
    "templateAssignmentTemplate",
    "removalModal",
    "removalModalContent",
    "removalModalLoader",
    "removalModalActionCount",
    "selectedTemplatesCount",
  ];

  static values = {
    templates: Array,
    templatesUrl: String,
    templatesParams: Object,
    templatesRemoved: Array,
    searchedTemplates: Array,
    translations: Object,
    removalToken: String,
    removalUrl: String,
    removalTemplateAssignmentId: Number,
    employeeId: String,
    templateId: String,
  };

  connect() {
    this.search();
  }

  async search() {
    const query = this.inputTarget.value;
    const excludedTemplateIds = this.templatesValue
      .map((template) => template.id)
      .join(" ");
    const params = new URLSearchParams({
      ...this.templatesParamsValue,
      excluded_template_ids: excludedTemplateIds,
      query,
    });
    const response = await fetch(
      `${this.templatesUrlValue}?${params.toString()}`,
    );
    const json = await response.json();
    this.searchedTemplatesValue = json.templates;
  }

  add(event) {
    event.preventDefault();

    const id = event.target.closest(".js-template").dataset.id;

    this.templateIdValue = id;

    const templateToAdd = this.searchedTemplatesValue.find(
      (template) => template.id.toString() === id,
    );

    if (templateToAdd && !this._isTemplateIncluded(templateToAdd)) {
      this.templatesValue = [...this.templatesValue, templateToAdd];

      this.searchedTemplatesValue = this.searchedTemplatesValue.filter(
        (template) => template.id !== templateToAdd.id,
      );

      this.templatesRemovedValue = this.templatesRemovedValue.filter(
        (template) => template.id !== templateToAdd.id,
      );

      track("Add template in track", {
        employee_id: this.employeeIdValue,
        template_id: this.templateIdValue,
      });
    }

    this.search();
  }

  cancelRemovalIfOutside(event) {
    if (
      !this.removalModalContentTarget.contains(event.target) &&
      this.removalModalTarget.contains(event.target)
    ) {
      this.removalModalTarget.hidden = true;
      this.removalModalContentTarget.hidden = true;
      this.removalModalLoaderTarget.hidden = true;
      this.removalTemplateAssignmentIdValue = 0;
    }
  }

  cancelRemoval(event) {
    event.preventDefault();

    this.removalModalTarget.hidden = true;
    this.removalModalContentTarget.hidden = true;
    this.removalModalLoaderTarget.hidden = true;
    this.removalTemplateAssignmentIdValue = 0;
  }

  confirmRemoval(event) {
    event.preventDefault();

    const params = new URLSearchParams({
      token: this.removalTokenValue,
      template_assignment_id: this.removalTemplateAssignmentIdValue,
    });

    fetch(`${this.removalUrlValue}?${params.toString()}`, {
      method: "DELETE",
      headers: { "x-csrf-token": csrfToken() },
    }).catch(() => {
      this.removalModalTarget.hidden = true;
      this.removalModalContentTarget.hidden = true;
      this.removalModalLoaderTarget.hidden = true;
      this.removalTemplateAssignmentIdValue = 0;
    });

    this.removalModalTarget.hidden = false;
    this.removalModalContentTarget.hidden = true;
    this.removalModalLoaderTarget.hidden = false;
    this.removalTemplateAssignmentIdValue = 0;

    track("Remove template in track", {
      employee_id: this.employeeIdValue,
      template_id: this.templateIdValue,
      resources_created: true,
    });
  }

  remove(event) {
    event.preventDefault();

    const id = event.target.closest(".js-template").dataset.id;

    this.templateIdValue = id;

    const templateToRemove = this.templatesValue.find(
      (template) => template.id.toString() === id,
    );

    if (templateToRemove) {
      if (templateToRemove.template_assignment_resources_created) {
        this.removalModalTarget.hidden = false;
        this.removalModalActionCountTarget.innerText =
          templateToRemove.action_count;
        this.removalModalContentTarget.hidden = false;
        this.removalModalLoaderTarget.hidden = true;

        this.removalTemplateAssignmentIdValue =
          templateToRemove.template_assignment_id;
      } else {
        this.templatesValue = this.templatesValue.filter(
          (template) => template.id !== templateToRemove.id,
        );

        this.searchedTemplatesValue = [
          ...this.searchedTemplatesValue,
          templateToRemove,
        ];

        this.templatesRemovedValue = [
          ...this.templatesRemovedValue,
          { ...templateToRemove, template_assignment_removed: true },
        ];

        track("Remove template in track", {
          employee_id: this.employeeIdValue,
          template_id: this.templateIdValue,
          resources_created: false,
        });
      }
    }
    this.search();
  }

  templatesRemovedValueChanged() {
    this.templatesValueChanged(this.templatesValue);
  }

  templatesValueChanged(templates) {
    this.selectedTemplatesCountTarget.innerText = this._translate(
      "selected_templates",
      { count: this.templatesValue.length },
    );

    this.selectedTemplatesTarget.innerHTML = templates
      .map((template) =>
        Object.entries(template).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateRemoveTarget.innerHTML,
        ),
      )
      .join("");

    this.selectedTemplatesTarget
      .querySelectorAll("[data-id]")
      .forEach((child) => {
        const childTemplate = templates.find(
          (template) => template.id.toString() === child.dataset.id,
        );

        child.querySelector(".js-resources-created").hidden =
          !childTemplate?.template_assignment_resources_created;
      });

    this.templateAssignmentsTarget.innerHTML = this._templateAssignments()
      .map((templateAssignment) =>
        Object.entries(templateAssignment).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateAssignmentTemplateTarget.innerHTML,
        ),
      )
      .join("");

    if (templates.length > 0) {
      this.selectedTemplatesContainerTarget.hidden = false;
      this.noSelectedTemplatesTarget.hidden = true;
    } else {
      this.selectedTemplatesContainerTarget.hidden = true;
      this.noSelectedTemplatesTarget.hidden = false;
    }
  }

  searchedTemplatesValueChanged(searchedTemplates) {
    this.allTemplatesTarget.innerHTML = searchedTemplates
      .map((template) =>
        Object.entries(template).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateAddTarget.innerHTML,
        ),
      )
      .join("");

    this.templatesCountTarget.innerText = this._translate("templates", {
      count: searchedTemplates.length,
    });
  }

  _isTemplateIncluded(template) {
    return this.templatesValue.map((other) => other.id).includes(template.id);
  }

  _translate(key, params) {
    const subKey = { 0: "zero", 1: "one" }[params.count] || "other";
    return this.translationsValue[key][subKey].replaceAll(
      "%{count}",
      params.count,
    );
  }

  _templateAssignments() {
    return this.templatesValue
      .concat(this.templatesRemovedValue)
      .map((template, index) => {
        return {
          index: index + 1,
          id: template.template_assignment_id,
          template_id: template.id,
          resources_created: template.template_assignment_resources_created,
          _destroy: template.template_assignment_removed,
        };
      });
  }

  removalTokenValueChanged(token) {
    if (!token) {
      return;
    }

    const self = this;

    App.cable.subscriptions.create(
      { channel: "RemovedTemplatesChannel", token },
      {
        received: (response) => {
          const id = response.template.id;

          const templateToRemove = self.templatesValue.find(
            (template) => template.id === id,
          );

          if (templateToRemove) {
            self.templatesValue = self.templatesValue.filter(
              (template) => template.id !== templateToRemove.id,
            );

            self.searchedTemplatesValue = [
              ...self.searchedTemplatesValue,
              templateToRemove,
            ];

            self.templatesRemovedValue = self.templatesRemovedValue.filter(
              (template) => template.id !== templateToRemove.id,
            );
          }

          self.search();

          self.removalModalTarget.hidden = true;
          self.removalModalContentTarget.hidden = true;
          self.removalModalLoaderTarget.hidden = true;
          self.removalTemplateAssignmentIdValue = 0;
        },
      },
    );
  }
}
