import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    "modalContainer",
    "modal",
    "addFile",
    "attachFile",
    "attachUrl",
    "attachFileTab",
    "attachUrlTab",
    "formContainer",
    "file",
    "fileDisplay",
    "fileDisplayName",
    "form",
    "dateContainer",
    "dueDate",
    "url",
    "submitButton",
  ];

  static classes = [
    "tabEnabled",
    "tabDisabled",
    "buttonEnabled",
    "buttonDisabled",
  ];

  static values = {
    actionsUrl: String,
  };

  submit(event) {
    event.preventDefault();

    const formData = new FormData(this.formTarget);
    formData.append("actor_id", this.element.dataset.actorId);
    formData.append("action_type", "Todo");

    fetch(this.actionsUrlValue, {
      method: "POST",
      headers: {
        "x-csrf-token": csrfToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((action) => {
        flash(this._translations().action_added, "notice");

        $(window).trigger("fetch-actions", { action });
      });

    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
    this._reset();
  }

  show() {
    this.modalContainerTarget.hidden = false;
    this.modalTarget.hidden = false;
    this._reset();
  }

  validate() {
    if (this.urlTarget.checkValidity()) {
      this.submitButtonTargets.forEach((submitButtonTarget) => {
        submitButtonTarget.disabled = false;
        submitButtonTarget.classList.add(...this.buttonEnabledClasses);
        submitButtonTarget.classList.remove(...this.buttonDisabledClasses);
      });
    } else {
      this.submitButtonTargets.forEach((submitButtonTarget) => {
        submitButtonTarget.disabled = true;
        submitButtonTarget.classList.remove(...this.buttonEnabledClasses);
        submitButtonTarget.classList.add(...this.buttonDisabledClasses);
      });
    }
  }

  switchAddFile(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.addFileTarget.hidden = false;
      this.attachFileTarget.hidden = false;
      this.attachUrlTarget.hidden = true;
    } else {
      this.addFileTarget.hidden = true;
      this.attachFileTarget.hidden = true;
      this.attachUrlTarget.hidden = true;
    }
  }

  chooseAttachFile(event) {
    event.preventDefault();

    this.attachFileTarget.hidden = false;
    this.attachUrlTarget.hidden = true;

    this.attachFileTabTarget.classList.add(...this.tabEnabledClasses);
    this.attachFileTabTarget.classList.remove(...this.tabDisabledClasses);
    this.attachUrlTabTarget.classList.remove(...this.tabEnabledClasses);
    this.attachUrlTabTarget.classList.add(...this.tabDisabledClasses);
  }

  chooseAttachUrl(event) {
    event.preventDefault();

    this.attachFileTarget.hidden = true;
    this.attachUrlTarget.hidden = false;

    this.attachFileTabTarget.classList.remove(...this.tabEnabledClasses);
    this.attachFileTabTarget.classList.add(...this.tabDisabledClasses);
    this.attachUrlTabTarget.classList.add(...this.tabEnabledClasses);
    this.attachUrlTabTarget.classList.remove(...this.tabDisabledClasses);
  }

  hideTodoModalIfOutside(event) {
    if (
      this.modalContainerTarget.contains(event.target) &&
      !this.modalTarget.contains(event.target)
    ) {
      this.modalContainerTarget.hidden = true;
      this.modalTarget.hidden = true;
      this._reset();
    }
  }

  backToBeginning(event) {
    event.preventDefault();

    $(window).trigger("add-action");
    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
    this._reset();
  }

  backToForm(event) {
    event.preventDefault();

    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
  }

  nextToDate(event) {
    event.preventDefault();

    this.formContainerTarget.hidden = true;
    this.dateContainerTarget.hidden = false;
  }

  addFile(event) {
    if (this.fileTarget.files[0]) {
      this.fileDisplayTarget.hidden = false;
      this.fileDisplayNameTarget.hidden = false;
      this.fileDisplayNameTarget.innerText = this.fileTarget.files[0].name;
    } else {
      this.fileDisplayTarget.hidden = true;
      this.fileDisplayNameTarget.hidden = true;
      this.fileDisplayNameTarget.innerText = "";
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.fileTarget.value = "";
    this.fileDisplayTarget.hidden = true;
    this.fileDisplayNameTarget.hidden = true;
    this.fileDisplayNameTarget.innerText = "";
  }

  _translations() {
    return JSON.parse(this.element.dataset.translations);
  }

  _reset() {
    this.formTarget.reset();
    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
    this.fileDisplayTarget.hidden = true;
    this.fileDisplayNameTarget.hidden = true;
    this.addFileTarget.hidden = true;
    this.attachFileTarget.hidden = true;
    this.attachUrlTarget.hidden = true;
    this.attachFileTabTarget.classList.add(...this.tabEnabledClasses);
    this.attachFileTabTarget.classList.remove(...this.tabDisabledClasses);
    this.attachUrlTabTarget.classList.remove(...this.tabEnabledClasses);
    this.attachUrlTabTarget.classList.add(...this.tabDisabledClasses);
    this.fileDisplayTarget.hidden = true;
    this.fileDisplayNameTarget.hidden = true;
    this.fileDisplayNameTarget.innerText = "";
    $(this.dueDateTarget).trigger("reset");
  }
}
