import { Controller } from "@hotwired/stimulus";

export class BaseController extends Controller {
  // buttonEnabledClasses = ["text-grey-800", "border-bottom-grey-800-2px"]
  // buttonDisabledClasses = ["text-grey-700", "border-bottom-grey-100-2px"]

  nextToDate(event) {
    event.preventDefault();

    this.formContainerTarget.hidden = true;
    this.dateContainerTarget.hidden = false;
  }

  hideModalIfOutside(event) {
    if (
      this.modalContainerTarget.contains(event.target) &&
      !this.modalTarget.contains(event.target)
    ) {
      this._hideModal();
    }
  }

  backToBeginning(event) {
    event.preventDefault();

    $(window).trigger("add-action");
    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
  }

  backToForm(event) {
    event.preventDefault();

    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
  }
}
