import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "datepicker",
    "input",
    "datepickerContainer",
    "startDateButton",
    "launchDateButton",
    "pickDateButton",
    "launchDateMessage",
  ];

  static values = {
    startDate: String,
    launchDate: String,
    locale: String,
    inputIsShown: Boolean,
  };

  connect() {
    this._setDatepicker();
    this._updateButtonClasses();
    this._updateTodayToStartDate();

    $(this.element).on("reset", () => {
      this.inputTarget.value = this.startDateValue;

      if (this.hasLaunchDateMessageTarget) {
        hideElement(this.launchDateMessageTarget);
      }

      showElement(this.datepickerContainerTarget);

      $(this.datepickerTarget).datetimepicker(
        "date",
        moment(this.startDateValue, "DD/MM/YYYY"),
      );

      this._updateButtonClasses();
      this._updateTodayToStartDate();
    });
  }

  focus(_event) {
    this._show();
    this._updateButtonClasses();
    this._updateTodayToStartDate();
  }

  blur(_event) {
    this._validateInputDate();
    this._hide();
  }

  pickDate(event) {
    event.preventDefault();

    if (this.hasLaunchDateMessageTarget) {
      hideElement(this.launchDateMessageTarget);
    }
    if (
      !this.inputTarget.value ||
      this.inputTarget.value == this.launchDateValue
    ) {
      $(this.datepickerTarget).datetimepicker(
        "date",
        moment(this.startDateValue, "DD/MM/YYYY"),
      );
    }
    this._show();
    this._updateButtonClasses();
    this._updateTodayToStartDate();
  }

  pickStartDate(event) {
    event.preventDefault();

    this.inputTarget.value = this.startDateValue;
    if (this.hasLaunchDateMessageTarget) {
      hideElement(this.launchDateMessageTarget);
    }
    $(this.datepickerTarget).datetimepicker(
      "date",
      moment(this.startDateValue, "DD/MM/YYYY"),
    );
    this.inputIsShownValue ? this._hide() : this._show();
    this._updateButtonClasses();
    this._updateTodayToStartDate();
  }

  pickLaunchDate(event) {
    event.preventDefault();

    this.inputTarget.value = this.launchDateValue;
    if (this.hasLaunchDateMessageTarget) {
      showElement(this.launchDateMessageTarget);
    }
    this._hide();
    this._updateButtonClasses();
    this._updateTodayToStartDate();
  }

  _show() {
    if (this.hasLaunchDateMessageTarget) {
      hideElement(this.launchDateMessageTarget);
    }
    if (this.inputTarget.value == this.launchDateValue) {
      this.inputTarget.value = this.startDateValue;
      $(this.datepickerTarget).datetimepicker(
        "date",
        moment(this.startDateValue, "DD/MM/YYYY"),
      );
    }
    showElement(this.datepickerContainerTarget);
  }

  _hide() {
    hideElement(this.datepickerContainerTarget);
  }

  _setDatepicker() {
    $(this.datepickerTarget).datetimepicker({
      format: "DD/MM/YYYY",
      locale: this.localeValue,
      date: this._initialDate(),
      inline: true,
    });

    $(this.datepickerTarget).on(
      "change.datetimepicker",
      this._onchange.bind(this),
    );

    this._updateButtonClasses();
    this._updateTodayToStartDate();

    $(this.datepickerTarget)
      .find("th.prev")
      .on("click", this._updateTodayToStartDate.bind(this));
    $(this.datepickerTarget)
      .find("th.next")
      .on("click", this._updateTodayToStartDate.bind(this));
    $(this.datepickerTarget)
      .find("th.prev")
      .on("click", () => {
        setTimeout(() => {
          this._updateTodayToStartDate();
        }, 100);
      });
    $(this.datepickerTarget)
      .find("th.next")
      .on("click", () => {
        setTimeout(() => {
          this._updateTodayToStartDate();
        }, 100);
      });
  }

  _onchange(event, data) {
    const date = data?.date || event.date;
    this.inputTarget.value = date.format("DD/MM/YYYY");
    if (this.inputIsShownValue) {
      this._hide();
    }
    this.inputTarget.blur();
    this._updateButtonClasses();
    this._updateTodayToStartDate();
  }

  _updateButtonClasses() {
    switch (this.inputTarget.value) {
      case this.startDateValue:
        activateButton(this.startDateButtonTarget);
        deactivateButton(this.launchDateButtonTarget);
        if (this.hasPickDateButtonTarget) {
          deactivateButton(this.pickDateButtonTarget);
        }
        break;
      case this.launchDateValue:
        deactivateButton(this.startDateButtonTarget);
        activateButton(this.launchDateButtonTarget);
        if (this.hasPickDateButtonTarget) {
          deactivateButton(this.pickDateButtonTarget);
        }
        break;
      default:
        deactivateButton(this.startDateButtonTarget);
        deactivateButton(this.launchDateButtonTarget);
        if (this.hasPickDateButtonTarget) {
          activateButton(this.pickDateButtonTarget);
        }
        break;
    }
  }

  _updateTodayToStartDate() {
    [...this.datepickerTarget.querySelectorAll("td.day")].forEach((td) => {
      if (td.dataset.day) {
        const startDate = moment(this.startDateValue, "DD/MM/YYYY");
        const day = moment(
          td.dataset.day,
          this.localeValue === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
        );

        if (day.isSame(startDate)) {
          td.classList.add("today");
        } else {
          td.classList.remove("today");
        }
      }
    });
  }

  _validateInputDate() {
    const dateValue = this.inputTarget.value;

    if (!dateValue || dateValue == this.launchDateValue) {
      dateValue = this.launchDateValue;
    } else if (!moment(dateValue, "DD/MM/YYYY", true).isValid()) {
      dateValue = $(this.datepickerTarget)
        .datetimepicker("date")
        .format("DD/MM/YYYY");
    }
  }

  _initialDate() {
    let date;

    switch (this.inputTarget.value) {
      case this.launchDateValue:
        date = this.startDateValue;
        break;
      case this.startDateValue:
        date = this.startDateValue;
        break;
      default:
        date = this.inputTarget.value;
        break;
    }

    return moment(date, "DD/MM/YYYY");
  }
}

const activateButton = (button) => {
  button.classList.add("active");
};

const deactivateButton = (button) => {
  button.classList.remove("active");
};

const showElement = (element) => {
  element.classList.remove("hidden");
};

const hideElement = (element) => {
  element.classList.add("hidden");
};
