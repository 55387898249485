import { Controller } from "@hotwired/stimulus"


// EXAMPLE :
//   <div data-controller="utils--simili-file-inputs">
//     <div data-utils--simili-file-inputs-target="button" class="button button-white">
//       <i class="fas fa-paperclip" aria-hidden="true"></i>
//     </div>
//     <div data-utils--simili-file-inputs-target="fileName">
//       // WILL BE COMPLETED BY UPLOADED FILE NAME
//     </div>
//     <div class="form-group file optional todo_file">
//       <input data-utils--simili-file-inputs-target="input" class="file optional d-none border-none" type="file" name="todo[file]" id="todo_file"></input>
//     </div>
//   </div>
export default class extends Controller {
  static targets = ['input', 'button', 'fileName']

  connect() {
    this.initializeClick();
    this.initializeFileNameDisplay();
  }

  // When you click on button -> click on hidden input
  initializeClick() {
    this.buttonTarget.addEventListener('click', () => {
      $(this.inputTarget).click();
    });
  }

  // When you upload a file -> your file name fields the fileName target
  initializeFileNameDisplay() {
    this.inputTarget.addEventListener('change', () => {
      this.changeFileNameValue()
    });
  }

  changeFileNameValue() {
    const fileName = this.inputTarget.value.split("\\").pop();
    const fileURL = URL.createObjectURL(this.inputTarget.files[0]);
    const link = document.createElement('a');
    link.href = fileURL;
    link.target = '_blank';
    link.textContent = fileName;
    link.classList.add('text-primary-700', 'hover-text-primary-800');
    this.fileNameTarget.innerHTML = '';
    this.fileNameTarget.appendChild(link)
  }
}


