import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'toDisplay']

  connect(event) {
    this.updateDisplay(event);
  }

  updateDisplay(event) {
    if (this.checkboxTarget.checked) {
      this.toDisplayTarget.classList.remove('hidden');
    } else {
      this.toDisplayTarget.classList.add('hidden');
    }
  }
}

