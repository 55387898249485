import { BaseController } from "./base_controller";
import { csrfToken } from "utils/csrf_token";
// import { escapeHtml } from "utils/escapeHtml";
// import { debounce } from "utils/debounce";

export default class KitController extends BaseController {
  static targets = [
    "modalContainer",
    "modal",
    "form",
    "dateContainer",
    "datepickerInput",
    "addActionButton",
  ];

  translations = JSON.parse(this.element.dataset.translations);

  show(event) {
    this.modalContainerTarget.hidden = false;
    this.modalTarget.hidden = false;
    this.dateContainerTarget.hidden = false;
    this.resourceParams = {
      resource_type: event.detail.resourceType,
      resource_id: event.detail.resourceId,
    };
  }

  async submit(event) {
    event.preventDefault();

    try {
      const response = await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          "content-type": "application/json",
          "x-csrf-token": csrfToken(),
        },
        body: JSON.stringify({
          ...this.resourceParams,
          track_id: this.trackIdValue,
          due_date: this.datepickerInputTarget.value,
        }),
      });

      if (response.ok) {
        const action = await response.json();
        flash(this.translations.action_added, "notice");

        // render the action
        $(window).trigger("fetch-actions", { action });

        this._resetData();
        this._hideModal();
      } else {
        flash(this.translations.action_added, "alert");
        console.error("Erreur de requête:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  }

  _hideModal() {
    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
    this.dateContainerTarget.hidden = true;
  }

  _resetData() {
    this.resourceParams = {};
    this.resourcesValue = [];
  }
}
