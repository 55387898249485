import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    "organiserContainer",
    "locationContainer",
    "descriptionContainer",
    "dueDateContainer",
    "datesContainer",
    "organiser",
    "location",
    "description",
    "dueDate",
    "participants",
    "startDate",
    "endDate",
    "organiserToggle",
    "organiserError",
  ];

  static values = {
    startDateBefore: String,
    endDateBefore: String,
    organiserId: String,
  };

  connect() {
    $(this.organiserTarget).on("change", (_, data) => {
      this.organiserIdValue = data?.id;
      this._validate();
      $(this.participantsTarget).trigger("add", data);
    });
  }

  focusStartDate(event) {
    this.startDateBeforeValue = this.startDateTarget.value;
  }

  changeStartDate() {
    this._fixStartDateAndEndDate("start_date");
  }

  focusEndDate(event) {
    this.endDateBeforeValue = this.endDateTarget.value;
  }

  changeEndDate() {
    this._fixStartDateAndEndDate("end_date");
  }

  submit(event) {
    if (!this._validate()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  toggleOrganizer(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.organiserContainerTarget.hidden = false;
      this.dueDateContainerTarget.hidden = false;
      this.datesContainerTarget.hidden = true;
    } else {
      this.organiserContainerTarget.hidden = true;
      this.dueDateContainerTarget.hidden = true;
      this.datesContainerTarget.hidden = false;
    }

    this._validate();
  }

  toggleLocation(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.locationContainerTarget.hidden = false;
    } else {
      this.locationContainerTarget.hidden = true;
    }

    this.locationTarget.value = "";
  }

  toggleDescription(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.descriptionContainerTarget.hidden = false;
    } else {
      this.descriptionContainerTarget.hidden = true;
    }

    this.descriptionTarget.value = "";
  }

  _fixStartDateAndEndDate(dateChanged) {
    const startDateBefore = moment(
      this.startDateBeforeValue || this.trackStartDateValue,
    );
    const endDateBefore = moment(
      this.endDateBeforeValue || this.trackStartDateValue,
    );
    const previousStartDate = moment(
      this.startDateTarget.value || this.trackStartDateValue,
    );
    const previousEndDate = moment(
      this.endDateTarget.value || this.trackStartDateValue,
    );

    if (
      previousEndDate.isBefore(previousStartDate) ||
      previousEndDate.isSame(previousStartDate)
    ) {
      if (dateChanged == "start_date") {
        const newEndDate = previousEndDate.add(
          previousStartDate.diff(startDateBefore, "seconds"),
          "seconds",
        );
        this.endDateTarget.value = newEndDate.format("YYYY-MM-DDTHH:mm");
        this.startDateTarget.value =
          previousStartDate.format("YYYY-MM-DDTHH:mm");
      } else {
        const newStartDate = previousStartDate.add(
          previousEndDate.diff(endDateBefore, "seconds"),
          "seconds",
        );
        this.startDateTarget.value = newStartDate.format("YYYY-MM-DDTHH:mm");
        this.endDateTarget.value = previousEndDate.format("YYYY-MM-DDTHH:mm");
      }
    }

    setTimeout(() => {
      if (!this.endDateTarget.value) {
        this.endDateTarget.value = previousEndDate.format("YYYY-MM-DDTHH:mm");
      }

      if (!this.startDateTarget.value) {
        this.startDateTarget.value =
          previousStartDate.format("YYYY-MM-DDTHH:mm");
      }
    }, 100);
  }

  _validate() {
    if (this.organiserToggleTarget.checked && !this.organiserIdValue) {
      this.organiserErrorTarget.hidden = false;
      return false;
    } else {
      this.organiserErrorTarget.hidden = true;
      return true;
    }
  }
}
