import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = [
    "hidden",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
  ];

  static values = {
    autocompleteUrl: String,
    autocompleteParams: Object,
    excludedIds: String,
    isPristine: { type: Boolean, default: true },
  };

  connect() {
    $(this.element).on("reset", () => {
      this.hiddenTarget.value = "";
      this.inputTarget.value = "";
      $(this.element).trigger("change");
    });

    this.search = debounce(this.search.bind(this), 100);
  }

  async search() {
    const query = this.isPristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({
      ...this.autocompleteParamsValue,
      ...this._excludedIds(),
      query,
    });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.employees
      .map((employee) =>
        Object.entries(employee).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
    }
  }

  touch() {
    this.isPristineValue = false;
  }

  reset() {
    if (!this.isPristineValue) {
      this.hiddenTarget.value = "";
      this.inputTarget.value = "";
      $(this.element).trigger("change");
    }
  }

  pick(event) {
    event.preventDefault();

    const option = event.target.closest(".input-select__option");

    this.hiddenTarget.value = option.dataset.id;
    this.inputTarget.value = option.dataset.fullName;
    this.dropdownTarget.hidden = true;
    this.isPristineValue = true;

    $(this.element).trigger("change", {
      id: option.dataset.id,
      full_name: option.dataset.fullName,
      initial: option.dataset.initial,
      email: option.dataset.email,
    });
  }

  _excludedIds() {
    return { excluded_ids: this.excludedIdsValue?.split(",") };
  }
}
