import { Controller } from "@hotwired/stimulus";
import { Calendar } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';


export default class extends Controller {
  static values = {
    eventUrl: String,
    callbackUrl: String,
    locale: String,
    start: String,
    meetingId: String
   };

  connect() {
    this.initializeCalendar();
    setTimeout(() => this.showMeeting(this.meetingIdValue), 300);
  }

  initializeCalendar(){
    let calendar = new Calendar(this.element, this.config());
    calendar.render();
  }

  renderEvent(info) {
    info.el.id = 'meeting-' + info.event.id
    info.el.classList.add('event-meeting')
  }

  clickOnEvent(info) {
    let meetingId = info.el.id.match(/\d+/)[0];
    this.showMeeting(meetingId);
  }

  showMeeting(meetingId) {
    if (meetingId) {
      let url = this.callbackUrlValue.replace("meeting_id", meetingId)
      $.ajax({
        url: url,
        type: "GET"
      });
    }
  }

  config() {
    return {
      locales: allLocales,
      locale: this.localeValue,
      plugins: [ dayGridPlugin, timeGridPlugin ],
      header: {
        left: 'timeGridDay,timeGridWeek,dayGridMonth,listCustom',
        center: 'title',
        right: 'prev,next '
      },
      events: this.eventUrlValue,
      eventLimit: 4,
      eventLimitClick: 'day',
      defaultDate: this.startValue,
      allDaySlot: false,
      slotDuration: '00:30:00',
      minTime: '07:00:00',
      maxTime: '20:00:00',
      nowIndicator: true,
      defaultView: 'dayGridMonth',
      eventRender: (info) => { this.renderEvent(info) },
      eventClick: (info) => { this.clickOnEvent(info) }
    }
  }
}
