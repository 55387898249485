export const track = (name, properties = {}) => {
  if (document.body.dataset.trueUser == 'true') {
    analytics.track(
      name,
      {
        url: window.location.href,
        referrer: document.referrer,
        current_sign_in_at: document.body.dataset.currentSignInAt,
        current_user_id: document.body.dataset.currentUserId,
        current_employee_id: document.body.dataset.currentEmployeeId,
        event_time: new Date().toISOString(),
        ...properties,
      }
    );
  }
}
