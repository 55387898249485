import { BaseController } from "./base_controller";
import { csrfToken } from "utils/csrf_token";

export default class extends BaseController {
  static targets = [
    "modalContainer",
    "modal",
    "form",
    "formContainer",
    "dateContainer",
    "organiserContainer",
    "locationContainer",
    "descriptionContainer",
    "formAddContainer",
    "formNextContainer",
    "startDate",
    "endDate",
    "participants",
    "dueDate",
    "location",
    "description",
    "organiser",
    "datesContainer",
    "organiserToggle",
    "organiserError",
  ];

  static values = {
    actionsUrl: String,
    defaultStartDate: String,
    defaultEndDate: String,
    startDateBefore: String,
    endDateBefore: String,
    organiserId: String,
  };

  translations = JSON.parse(this.element.dataset.translations);

  connect() {
    $(this.organiserTarget).on("change", (_, data) => {
      this.organiserIdValue = data?.id;
      this._validate();
      $(this.participantsTarget).trigger("add", data);
    });
  }

  show(event) {
    this.modalContainerTarget.hidden = false;
    this.modalTarget.hidden = false;
    this._reset();
  }

  submit(event) {
    event.preventDefault();

    if (!this._validate()) {
      return;
    }

    const formData = new FormData(this.formTarget);
    formData.append("actor_id", this.element.dataset.actorId);
    formData.append("action_type", "Meeting");

    fetch(this.actionsUrlValue, {
      method: "POST",
      headers: {
        "x-csrf-token": csrfToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((action) => {
        flash(this.translations.action_added, "notice");

        $(window).trigger("fetch-actions", { action });
      });

    this._hideModal();
    this._reset();
  }

  focusStartDate(event) {
    this.startDateBeforeValue = this.startDateTarget.value;
  }

  changeStartDate() {
    this._fixStartDateAndEndDate("start_date");
  }

  focusEndDate(event) {
    this.endDateBeforeValue = this.endDateTarget.value;
  }

  changeEndDate() {
    this._fixStartDateAndEndDate("end_date");
  }

  nextToDate(event) {
    event.preventDefault();

    if (this._validate()) {
      this.formContainerTarget.hidden = true;
      this.dateContainerTarget.hidden = false;
    }
  }

  backToForm(event) {
    event.preventDefault();

    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
  }

  toggleOrganizer(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.organiserContainerTarget.hidden = false;
      this.formAddContainerTarget.hidden = true;
      this.formNextContainerTarget.hidden = false;
      this.datesContainerTarget.hidden = true;
    } else {
      this.organiserContainerTarget.hidden = true;
      this.formAddContainerTarget.hidden = false;
      this.formNextContainerTarget.hidden = true;
      this.datesContainerTarget.hidden = false;
    }

    this._validate();
  }

  toggleLocation(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.locationContainerTarget.hidden = false;
    } else {
      this.locationContainerTarget.hidden = true;
    }

    this.locationTarget.value = "";
  }

  toggleDescription(event) {
    if (event.target.closest("input[type=checkbox]").checked) {
      this.descriptionContainerTarget.hidden = false;
    } else {
      this.descriptionContainerTarget.hidden = true;
    }

    this.descriptionTarget.value = "";
  }

  _hideModal() {
    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
    this._reset();
  }

  _reset() {
    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
    this.formAddContainerTarget.hidden = false;
    this.formNextContainerTarget.hidden = true;
    this.formTarget.reset();
    this.startDateTarget.value = this.defaultStartDateValue;
    this.endDateTarget.value = this.defaultEndDateValue;
    this.organiserContainerTarget.hidden = true;
    this.locationContainerTarget.hidden = true;
    this.descriptionContainerTarget.hidden = true;
    this.datesContainerTarget.hidden = false;
    this.organiserIdValue = "";
    $(this.participantsTarget).trigger("reset");
    $(this.dueDateTarget).trigger("reset");
  }

  _fixStartDateAndEndDate(dateChanged) {
    const startDateBefore = moment(
      this.startDateBeforeValue || this.trackStartDateValue,
    );
    const endDateBefore = moment(
      this.endDateBeforeValue || this.trackStartDateValue,
    );
    const previousStartDate = moment(
      this.startDateTarget.value || this.trackStartDateValue,
    );
    const previousEndDate = moment(
      this.endDateTarget.value || this.trackStartDateValue,
    );

    if (
      previousEndDate.isBefore(previousStartDate) ||
      previousEndDate.isSame(previousStartDate)
    ) {
      if (dateChanged == "start_date") {
        const newEndDate = previousEndDate.add(
          previousStartDate.diff(startDateBefore, "seconds"),
          "seconds",
        );
        this.endDateTarget.value = newEndDate.format("YYYY-MM-DDTHH:mm");
        this.startDateTarget.value =
          previousStartDate.format("YYYY-MM-DDTHH:mm");
      } else {
        const newStartDate = previousStartDate.add(
          previousEndDate.diff(endDateBefore, "seconds"),
          "seconds",
        );
        this.startDateTarget.value = newStartDate.format("YYYY-MM-DDTHH:mm");
        this.endDateTarget.value = previousEndDate.format("YYYY-MM-DDTHH:mm");
      }
    }

    setTimeout(() => {
      if (!this.endDateTarget.value) {
        this.endDateTarget.value = previousEndDate.format("YYYY-MM-DDTHH:mm");
      }

      if (!this.startDateTarget.value) {
        this.startDateTarget.value =
          previousStartDate.format("YYYY-MM-DDTHH:mm");
      }
    }, 100);
  }

  _validate() {
    if (this.organiserToggleTarget.checked && !this.organiserIdValue) {
      this.organiserErrorTarget.hidden = false;
      return false;
    } else {
      this.organiserErrorTarget.hidden = true;
      return true;
    }
  }
}
