import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { missingLink: Boolean }

  connect() {
    if (this.missingLinkValue) {
      // By removing the 'fade' classes, the modal will be re-rendered
      // with validation errors with no animation
      $('#training_resource_add_link').removeClass('fade');
      $('#training_resource_add_resource').removeClass('fade');
      $('#training_resource_add_link').modal('show');
    };
  }
}
