import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = [
    "hiddenInput",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
    "option",
  ];
  static values = {
    value: String,
    isPristine: { type: Boolean, default: true },
  };

  connect() {
    this.valueValue = this.inputTarget.value;

    $(this.element).on("change-organization", (_, data) => {
      if (this.inputTarget.disabled) {
        return;
      }

      const option = this.optionTargets.find(
        (optionTarget) => optionTarget.dataset.value === data.language,
      );
      this.inputTarget.value = option.dataset.label;
      this.hiddenInputTarget.value = option.dataset.value;
      this.valueValue = option.dataset.label;
      this.dropdownTarget.hidden = true;
      this.isPristineValue = true;
      this._workeloBlur(this.inputTarget);
    });
  }

  search() {
    this.valueValue = this.inputTarget.value;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    this.dropdownTarget.hidden = !this.dropdownTarget.hidden;
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  pick(event) {
    event.preventDefault();

    const option = event.target.closest(".input-select__option");

    this.inputTarget.value = option.dataset.label;
    this.hiddenInputTarget.value = option.dataset.value;
    this.valueValue = option.dataset.label;
    this.dropdownTarget.hidden = true;
    this.isPristineValue = true;
    this._workeloBlur(this.inputTarget);
  }

  reset() {
    if (!this.isPristineValue) {
      this.inputTarget.value = "";
      this.hiddenInputTarget.value = "";
      this.valueValue = "";
    }
  }

  touch() {
    this.isPristineValue = false;
  }

  valueValueChanged(value) {
    if (this.isPristineValue || !value) {
      this.optionTargets.forEach(
        (optionTarget) => (optionTarget.hidden = false),
      );
    } else {
      this.optionTargets.forEach(
        (optionTarget) =>
          (optionTarget.hidden = !this._matches(optionTarget.dataset.label)),
      );
    }

    if (this.optionTargets.every((optionTarget) => optionTarget.hidden)) {
      this.noneTarget.hidden = false;
    } else {
      this.noneTarget.hidden = true;
    }
  }

  _matches(label) {
    if (label) {
      return this._normalize(label).includes(this._normalize(this.valueValue));
    } else {
      return true;
    }
  }

  _normalize(string) {
    return (string || "")
      .toString()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }
}
