import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "firstName", "email", "label"];

  static values = {
    firstName: String,
    email: String,
    test: Boolean,
    lastFirstName: String,
    lastEmail: String,
    isPristine: { type: Boolean, default: true },
  };

  static classes = ["enabled", "disabled"];

  connect() {
    this.testValue = this.inputTarget.checked;
  }

  toggle() {
    this.testValue = this.inputTarget.checked;
    this.isPristineValue = false;
  }

  testValueChanged(test) {
    if (test) {
      this.lastFirstNameValue = this.firstNameTarget.value;
      this.lastEmailValue = this.emailTarget.value;
      this.firstNameTarget.value = this.firstNameValue;
      this.emailTarget.value = this.emailValue;
      this.firstNameTarget.classList.add(...this.disabledClasses);
      this.emailTarget.classList.add(...this.disabledClasses);
      this.firstNameTarget.classList.remove(...this.enabledClasses);
      this.emailTarget.classList.remove(...this.enabledClasses);
      if (this.hasLabelTarget) {
        this.labelTarget.hidden = false;
      }
    } else if (!this.isPristineValue) {
      this.firstNameTarget.value = this.lastFirstNameValue;
      this.emailTarget.value = this.lastEmailValue;
      this.firstNameTarget.classList.remove(...this.disabledClasses);
      this.emailTarget.classList.remove(...this.disabledClasses);
      this.firstNameTarget.classList.add(...this.enabledClasses);
      this.emailTarget.classList.add(...this.enabledClasses);
      if (this.hasLabelTarget) {
        this.labelTarget.hidden = true;
      }
    }

    this._workeloTest(this.firstNameTarget);
    this._workeloTest(this.emailTarget);
  }

  _workeloTest(target) {
    $(target).trigger("workelo:test", { target });
  }
}
