import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    "currentResourceType",
    "resourceTypes",
    "resourceQuery",
    "resources",
    "resourceTemplate",
    "resourceTypes",
    "actionSearchModalContainer",
    "actionSearchModal",
  ];

  static values = {
    trackId: Number,
    hasFetchedResources: Boolean,
    resourcesUrl: String,
    resourceType: Object,
    resources: Array,
    resource: Object,
    actionsUrl: String,
    translations: Object,
  };

  connect() {
    this.actionSearchModalContainerTarget.hidden = true;
    this.actionSearchModalTarget.hidden = true;

    this.fetchResources = debounce(this.fetchResources.bind(this), 50);

    this._render();

    $(window).on("add-action", () => {
      this.actionSearchModalContainerTarget.hidden = false;
      this.actionSearchModalTarget.hidden = false;
    });
  }

  async fetchResources() {
    if (!this.resourceQueryTarget.value && !this.resourceTypeValue.type) {
      return;
    }

    const params = new URLSearchParams({
      resource_type: this.resourceTypeValue.type || "",
      query: this.resourceQueryTarget.value || "",
    });
    const response = await fetch(
      `${this.resourcesUrlValue}&${params.toString()}`,
    );
    const json = await response.json();
    this.resourcesValue = json.resources;
    this.hasFetchedResourcesValue = true;
    this._render();
  }

  pickResourceType(event) {
    event.preventDefault();

    const resourceTypeElement = event.target.closest(".js-resource-type");
    const type = resourceTypeElement.dataset.resourceType;
    const name = resourceTypeElement.dataset.resourceTypeName;

    if (type === "todo" || type === "meeting" || type === "handover") {
      const event = new CustomEvent(`track.add.${type}`);
      window.dispatchEvent(event);

      this.actionSearchModalContainerTarget.hidden = true;
      this.actionSearchModalTarget.hidden = true;
    } else {
      this.resourceTypeValue = { type, name };
      this.resourceQueryTarget.focus();
      this.hasFetchedResourcesValue = false;
      this.fetchResources();
      this._render();
    }
  }

  resetResourceType(event) {
    event.preventDefault();

    this.resourceTypeValue = {};
    this.resourceQueryTarget.focus();
    this.resourcesValue = [];
    this.hasFetchedResourcesValue = false;
    this._render();
  }

  updateResourceQuery() {
    this.fetchResources();
    this._render();
  }

  keydownResourceQuery(event) {
    if (event.key === "Backspace" && !this.resourceQueryTarget.value) {
      this.resetResourceType(event);
    }
  }

  focusResourceQuery(event) {
    event.preventDefault();

    this.resourceQueryTarget.focus();
    this._render();
  }

  showActionSearchModal(event) {
    event.preventDefault();

    this.actionSearchModalContainerTarget.hidden = false;
    this.actionSearchModalTarget.hidden = false;

    this.resourceQueryTarget.focus();
    this._render();
  }

  hideActionSearchModalIfOutside(event) {
    if (
      this.actionSearchModalContainerTarget.contains(event.target) &&
      !this.actionSearchModalTarget.contains(event.target)
    ) {
      this.resourcesValue = [];
      this.hasFetchedResourcesValue = false;
      this.actionSearchModalContainerTarget.hidden = true;
      this.actionSearchModalTarget.hidden = true;
      this._render();
    }
  }

  pickResource(event) {
    event.preventDefault();

    const resourceElement = event.target.closest(".js-resource");
    const id = resourceElement.dataset.id;
    const type = resourceElement.dataset.type;
    const suffix =
      type == "WorkflowResource" ? `.${resourceElement.dataset.detail}` : "";

    let actionType = type.replace("Resource", "").toLowerCase();
    let eventName = `track.add.${actionType}${suffix}`;
    const customEvent = new CustomEvent(eventName, {
      detail: { resourceType: type, resourceId: id },
    });
    window.dispatchEvent(customEvent);

    this.resourcesValue = [];
    this.resourceTypeValue = {};
    this.hasFetchedResourcesValue = false;
    this.actionSearchModalContainerTarget.hidden = true;
    this.actionSearchModalTarget.hidden = true;
    this._render();
  }

  _render() {
    if (
      (this.resourceQueryTarget.value || this.resourceTypeValue.type) &&
      (this.resourcesValue.length || this.hasFetchedResourcesValue)
    ) {
      this.resourceQueryTarget.placeholder = "";

      this.resourcesTarget.innerHTML = this.resourcesValue
        .map((resource) =>
          Object.entries(resource).reduce(
            (result, [key, value]) =>
              result.replaceAll(`{{${key}}}`, escapeHtml(value)),
            this.resourceTemplateTarget.innerHTML,
          ),
        )
        .join("");
      this.resourceTypesTarget.hidden = true;
      this.resourcesTarget.hidden = false;
    } else {
      this.resourceQueryTarget.placeholder =
        this._translations().resource_query_placeholder;
      this.resourceTypesTarget.hidden = false;
      this.resourcesTarget.hidden = true;
    }

    this.currentResourceTypeTarget.hidden = !this.resourceTypeValue.type;
    this.currentResourceTypeTarget.innerText =
      this.resourceTypeValue.name || "";
  }

  _translations() {
    return JSON.parse(this.element.dataset.translations);
  }
}
