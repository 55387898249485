import { Controller } from "@hotwired/stimulus";
import { stringToHTML, htmlToString } from "utils/convert_html";

export default class extends Controller {
  static targets = ['list', 'group']

  initialFieldIds = [...this.element.querySelectorAll('[iteration-group="0"] [data-field-id]')].map(el => el.dataset.fieldId);
  firstBlock = this.groupTargets[0];
  iterationNumber = 0;

  add() {
    this.iterationNumber += 1
    const newIterationBlock = this.buildNewIterationBlock();
    this.listTarget.insertAdjacentHTML('beforeEnd', newIterationBlock);
    this.duplicateConditionalLogics();
  }

  iterationBlock() {
    return this.groupTargets.find((el) => el.getAttribute('iteration-group') == 0 )
  }

  duplicateConditionalLogics() {
    document.querySelectorAll("[data-controller='desktop--form--conditional-logics']").forEach(conditionalLogic => {
      const conditionalLogicFieldIds = [...conditionalLogic.querySelectorAll('div[data-field-id]')].map(el => el.dataset.fieldId);
      const logicConditionFieldIds = [...conditionalLogic.querySelectorAll('div[data-desktop--form--conditional-logics-target="condition"][data-field-id]')].map(el => el.dataset.fieldId);
      const logicActionFieldIds = [...conditionalLogic.querySelectorAll('div[data-desktop--form--conditional-logics-target="action"][data-field-id]')].map(el => el.dataset.fieldId);

      if (this.isInternal(conditionalLogicFieldIds) || this.isExternalByCondition(logicConditionFieldIds)) {
        this.duplicateConditionalLogic(conditionalLogic);
      } else if (this.isExternalByAction(logicActionFieldIds)) {
        this.duplicateLogicActions(conditionalLogic)
      }
    })
  }

  isInternal(conditionalLogicFieldIds) {
    return conditionalLogicFieldIds.every(value => this.initialFieldIds.includes(value));
  }

  isExternalByCondition(logicConditionFieldIds) {
    return logicConditionFieldIds.some(value => this.initialFieldIds.includes(value));
  }

  isExternalByAction(logicActionFieldIds) {
    return logicActionFieldIds.some(value => this.initialFieldIds.includes(value));
  }

  duplicateConditionalLogic(conditionalLogic) {
    const newConditionalLogic = this.buildConditionalLogic(conditionalLogic);
    conditionalLogic.insertAdjacentHTML('afterEnd', newConditionalLogic);
  }

  duplicateLogicActions(conditionalLogic) {
    const logicActions = [...conditionalLogic.querySelectorAll('[data-controller="desktop--form--logic-actions"]')].filter(logicAction => {
      return this.initialFieldIds.includes(logicAction.dataset.fieldId)
    })

    logicActions.forEach(logicAction => {
      const newLogicAction = this.buildNewLogicAction(logicAction);
      logicAction.insertAdjacentHTML('afterEnd', newLogicAction);
    })
  }


  buildNewIterationBlock() {
    let iterationBlockHTML = this.firstBlock.outerHTML;
    iterationBlockHTML = iterationBlockHTML.replace('iteration-group="0"', `iteration-group="${this.iterationNumber}"`);
    iterationBlockHTML = iterationBlockHTML.replaceAll('paperwork_resource_', `paperwork_resource_i${this.iterationNumber}-`);
    iterationBlockHTML = iterationBlockHTML.replaceAll('data-input-field-id="', `data-input-field-id="i${this.iterationNumber}-`);
    iterationBlockHTML = iterationBlockHTML.replaceAll('data-field-id="', `data-field-id="i${this.iterationNumber}-`);
    iterationBlockHTML = iterationBlockHTML.replaceAll(/data-logic-action-show-\d+=\"(true|false)\"/g, "");
    iterationBlockHTML = removeSelectize(iterationBlockHTML);
    iterationBlockHTML = removeHasError(iterationBlockHTML);

    return iterationBlockHTML
  }

  buildConditionalLogic(conditionalLogic) {
    let newConditionalLogic = conditionalLogic.outerHTML;
    newConditionalLogic = newConditionalLogic.replaceAll('id="conditional_logic_', `id="conditional_logic_i${this.iterationNumber}-`);
    newConditionalLogic = newConditionalLogic.replaceAll('id="logic_condition_', `id="logic_condition_i${this.iterationNumber}-`);
    newConditionalLogic = newConditionalLogic.replaceAll('id="logic_action_', `id="logic_action_i${this.iterationNumber}-`);
    this.initialFieldIds.forEach(fieldId => {
      newConditionalLogic = newConditionalLogic.replaceAll(`data-field-id="${fieldId}"`, `data-field-id="i${this.iterationNumber}-${fieldId}"`);
    })

    return newConditionalLogic;
  }

  buildNewLogicAction(logicAction) {
    let newLogicAction = logicAction.outerHTML;
    newLogicAction = newLogicAction.replaceAll('id="logic_action_', `id="logic_action_i${this.iterationNumber}-`);
    newLogicAction = newLogicAction.replaceAll('data-field-id="', `data-field-id="i${this.iterationNumber}-`);

    return newLogicAction;
  }
}

function removeSelectize(string) {
  const html = stringToHTML(string)
  html.querySelectorAll('[data-controller="utils--selectizes-paperworks"]').forEach((el) => {
    el.style.display = "block";
    el.parentElement.querySelector('.selectize-control').remove();
  })
  return htmlToString(html)
}

function removeHasError(string) {
  const html = stringToHTML(string)
  html.querySelectorAll(".has-error").forEach((el) => {
    el.querySelector('.help-block').remove();
    el.classList.remove('has-error');
  })
  return htmlToString(html)
}


