import { Controller } from "@hotwired/stimulus"

// 2 targets are mandatory for this controller to work:
// - list: the list of elements to check if it's empty or not
// - blankState: the element to show or hide

// 1 optional target:
// - listContainer: the element that contains the list of elements
// This optionnal target is to handle the case where the list itself is injected.
// In case the list can't be appened to the DOM, we handle it with its container, if empty (no list), we show the blank state

// Example :
//    <div data-controller='utils--blank-states' data-utils--blank-states-target="listContainer">
//      <div data-utils--blank-states-target="list">
//      </div>
//
//      <div data-utils--blank-states-target='blankState'>
//      </div>
//    </div>
export default class extends Controller {
  static targets = ['list', 'blankState', 'listContainer']

  connect() {
    const interval = setInterval(() => {
      if (this.hasListTarget) {
        clearInterval(interval);
        this.displayOrHideBlankState();
        this.observer = new MutationObserver(this.displayOrHideBlankState.bind(this));
        this.observer.observe(this.listTarget, { childList: true });
      }
      if (this.hasListContainerTarget && this.listContainerTarget.innerHTML === '' && !this.hasListTarget) {
        clearInterval(interval);
        this.showBlankState()
      }
    }, 200);
  }

  displayOrHideBlankState() {
    this.isListEmpty() ? this.showBlankState() : this.hideBlankState()
  }

  showBlankState() {
    this.blankStateTarget.classList.remove('d-none');
    this.blankStateTarget.classList.add('d-flex');
  }

  hideBlankState() {
    this.blankStateTarget.classList.add('d-none');
    this.blankStateTarget.classList.remove('d-flex');
  }

  isListEmpty() {
    return this.listTarget.childElementCount === 0;
  }
}
