import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['modal', 'content']

  connect() {
    let observer = new MutationObserver(this._observeHiddenMutation.bind(this));
    observer.observe(this.modalTarget, { attributes: true });

    this.modalTarget.addEventListener('modalbehavior.show', this.show.bind(this));
    this.modalTarget.addEventListener('modalbehavior.hide', this.hide.bind(this));
  }

  hideModalIfOutside(event) {
    if (
      this.modalTarget.contains(event.target) && !this.contentTarget.contains(event.target)
    ) {
      this.hide();
    }
  }

  show(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();

      // trigger the show callback (optional)
      event.detail?.showCallback();
      // store the hide callback (optional)
      this.hideCallback = event.detail?.hideCallback;
    }
    this.modalTarget.hidden = false;
  }

  hide(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // used the stored hide callback (optional)
    if (this.hideCallback) { this.hideCallback(); }
    this.modalTarget.hidden = true;
  }

  _observeHiddenMutation(mutationsList) {
    for(let mutation of mutationsList) {
      let attributeName = mutation.attributeName;
      if (attributeName == "hidden") {
        this._declareHiddenMutation();
      }
    }
  }

  _declareHiddenMutation() {
    if (this.modalTarget.hidden == 'true') {
      let event = new CustomEvent('modalbehavior.hidden', { detail: { modal: this.modalTarget } });
      this.modalTarget.dispatchEvent(event);
    } else {
      let event = new CustomEvent('modalbehavior.shown', { detail: { modal: this.modalTarget } });
      this.modalTarget.dispatchEvent(event);
    }
  }
}
