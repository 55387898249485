import { BaseController } from "./base_controller";
import { csrfToken } from "utils/csrf_token";
// import { escapeHtml } from "utils/escapeHtml";
// import { debounce } from "utils/debounce";

export default class extends BaseController {
  static targets = [
    "modalContainer",
    "modal",
    "formContainer",
    "form",
    "dateContainer",
    "datepickerInput",
    "addActionButton",
  ];

  translations = JSON.parse(this.element.dataset.translations);

  show(event) {
    this.modalContainerTarget.hidden = false;
    this.modalTarget.hidden = false;
    this.formContainerTarget.hidden = false;
    this.dateContainerTarget.hidden = true;
    this.resourceParams = {
      resource_type: event.detail.resourceType,
      resource_id: event.detail.resourceId,
    };
  }

  async submit(event) {
    event.preventDefault();
    const rawFormParams = Object.fromEntries(new FormData(this.formTarget));
    const formParams = cleanParams(rawFormParams);

    try {
      const response = await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          "content-type": "application/json",
          "x-csrf-token": csrfToken(),
        },
        body: JSON.stringify({
          ...this.resourceParams,
          track_id: this.trackIdValue,
          due_date: this.datepickerInputTarget.value,
          ...formParams,
        }),
      });

      if (response.ok) {
        const action = await response.json();
        flash(this.translations.action_added, "notice");

        // render the action
        $(window).trigger("fetch-actions", { action });

        this._resetData();
        this._hideModal();
      } else {
        console.error("Erreur de requête:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  }

  _hideModal() {
    this.modalContainerTarget.hidden = true;
    this.modalTarget.hidden = true;
    this.formContainerTarget.hidden = true;
    this.dateContainerTarget.hidden = true;
  }

  _resetData() {
    this.resourceParams = {};
    this.resourcesValue = [];
  }
}

const cleanParams = (params) => {
  params["recipients"] = {};

  ["hr", "second_hr", "manager", "second_manager", "buddy"].forEach(
    (recipientName) => {
      let keyToRemove = `recipients[${recipientName}]`;
      params["recipients"][recipientName] = params[keyToRemove];

      delete params[keyToRemove];
    },
  );

  return params;
};
