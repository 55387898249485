import { Controller } from "@hotwired/stimulus";
import { track } from "utils/tracking";

export default class extends Controller {
  static targets = ["modal", "modalContent", "submit"];

  static values = {
    mobility: String,
    employeeId: String,
  };

  connect() {
    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = false;
  }

  showModalIfNotVisible(event) {
    if (this.modalTarget.hidden) {
      event.preventDefault();

      this.modalTarget.hidden = false;
      this.modalContentTarget.hidden = false;
      this.submitTarget.disabled = true;

      track("Finalize boarding track", {
        mobility: this.mobilityValue,
        employee_id: this.employeeIdValue,
      });
    }
  }

  hideModalIfOutside(event) {
    if (
      this.modalTarget.contains(event.target) &&
      !this.modalContentTarget.contains(event.target)
    ) {
      this.modalTarget.hidden = true;
      this.modalContentTarget.hidden = true;
      this.submitTarget.disabled = false;
    }
  }

  cancel(event) {
    event.preventDefault();

    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = false;
  }

  confirm(event) {
    event.preventDefault();

    this.element.requestSubmit();

    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = true;
  }
}
