import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = ["link"];
  static values = { url: String };

  connect() {
  }

  switchVersion(event) {
    event.preventDefault();
    const targetUrl = event.currentTarget.href;

    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        employee: {
          settings: {
            interface_version: "V2"
          }
        }
      })
    })
    .then(response => response.json())
    .then(responseJSON => {
      window.location.href = targetUrl;
    });
  }

  switchTrackCreationFlowVersion(event) {
    event.preventDefault();
    const targetUrl = event.currentTarget.href;

    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        employee: {
          settings: {
            track_creation_flow_version: "V2"
          }
        }
      })
    })
    .then(response => response.json())
    .then(responseJSON => {
      window.location.href = targetUrl;
    });
  }
}
