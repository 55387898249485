import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    "hiddenId",
    "hiddenEmail",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
    "invalidMesssage",
  ];

  emailRegex = new RegExp(
    "^[a-zA-Z0-9._%+-]+(?:\.[a-zA-Z0-9._%+-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
  );

  static values = {
    autocompleteUrl: String,
    autocompleteParams: Object,
    excludedIds: String,
    isPristine: { type: Boolean, default: true },
  };

  connect() {
    this.search = debounce(this.search.bind(this), 100);
    this.checkValueAsAnEmail = debounce(
      this.checkValueAsAnEmail.bind(this),
      100,
    );
  }

  async search(_event) {
    const query = this.isPristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({
      ...this.autocompleteParamsValue,
      ...this._excludedIds(),
      query,
    });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.employees
      .map((employee) =>
        Object.entries(employee).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
    this.checkValueAsAnEmail(_event);
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
    }
  }

  touch() {
    this.isPristineValue = false;
  }

  reset() {
    if (!this.isPristineValue) {
      this._define_hiddenId_as("");
      this.inputTarget.value = "";
    }
  }

  async checkValueAsAnEmail(_event) {
    const inputValue = this.inputTarget.value;

    if (this.emailRegex.test(inputValue)) {
      // Hard verify the email
      const response = await fetch("/verify_email", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: inputValue,
        }),
      });

      if (response.ok) {
        // Email valid
        this._define_hiddenEmail_as(inputValue);
        this.isPristineValue = true;
        this._setInputAsValid();
      } else {
        // Email Invalid
        this._setInputAsInvalid();
      }
    } else {
      // Not an email case
      this._setInputAsValid();
    }
  }

  pick(event) {
    event.preventDefault();
    event.stopPropagation();

    const option = event.target.closest(".input-select__option");

    this._define_hiddenId_as(option.dataset.id);
    this.inputTarget.value = option.dataset.fullName;
    this.dropdownTarget.hidden = true;
    this.isPristineValue = true;
  }

  _excludedIds() {
    return { excluded_ids: this.excludedIdsValue?.split(",") };
  }

  _define_hiddenId_as(value) {
    this.hiddenIdTarget.value = value;
    this.hiddenEmailTarget.value = "";
  }

  _define_hiddenEmail_as(value) {
    this.hiddenEmailTarget.value = value;
    this.hiddenIdTarget.value = "";
  }

  _setInputAsValid() {
    this.invalidMesssageTarget.setAttribute("hidden", "true");
    this.inputTarget.parentElement.classList.remove("border-color-danger-400");
  }

  _setInputAsInvalid() {
    this.invalidMesssageTarget.removeAttribute("hidden");
    this.inputTarget.parentElement.classList.add("border-color-danger-400");
  }
}
