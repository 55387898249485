import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { track } from "utils/tracking";

export default class extends Controller {
  static targets = [
    "startDatePicker",
    "endDatePicker",
    "startDateContainer",
    "endDateContainer",
    "startDate",
    "endDate",
    "select",
    "dropdown",
    "modalContainer",
    "modalContent",
    "shiftStartDateTitle",
    "shiftEndDateTitle",
    "shiftStartDateDescription",
    "shiftEndDateDescription",
    "notShiftDates",
    "notifyShift",
  ];

  static values = {
    locale: String,
    shiftDates: Boolean,
    shiftDatesUrl: String,
    isPristineEndDate: { type: Boolean, default: true },
    isPristineStartDate: { type: Boolean, default: true },
    pickingDate: { type: Boolean, default: false },
    oldStartDate: String,
    newStartDate: String,
    oldEndDate: String,
    newEndDate: String,
    defaultStartDate: String,
    defaultEndDate: String,
    mobility: String,
    employeeId: String,
  };

  connect() {
    $(this.startDatePickerTarget).datetimepicker({
      inline: true,
      format: "DD/MM/YYYY",
      locale: this.localeValue,
      date: moment(this.defaultStartDateValue, "DD/MM/YYYY"),
    });

    $(this.endDatePickerTarget).datetimepicker({
      inline: true,
      format: "DD/MM/YYYY",
      locale: this.localeValue,
      date: moment(this.defaultEndDateValue, "DD/MM/YYYY"),
    });

    this._hide(this.startDatePickerTarget);
    this._hide(this.endDatePickerTarget);

    $(this.startDatePickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;
      this._changeStartDate(date.format("DD/MM/YYYY"));
      this.isPristineStartDateValue = true;
      this._hide(this.startDatePickerTarget);
    });

    $(this.endDatePickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;
      this._changeEndDate(date.format("DD/MM/YYYY"));
      this.isPristineEndDateValue = true;
      this.pickingDateValue = false;
      this._hide(this.endDatePickerTarget);
      this._workeloBlur(this.endDateTarget);
    });
  }

  showStartDatePicker() {
    this._show(this.startDatePickerTarget);
  }

  hideStartDatePicker() {
    this._hide(this.startDatePickerTarget);
  }

  hideStartDatePickerIfOutside() {
    if (!this.startDateContainerTarget.contains(event.target)) {
      this._hide(this.startDatePickerTarget);
    }
  }

  showEndDatePicker() {
    this._show(this.endDatePickerTarget);
  }

  toggle(event) {
    event.preventDefault();
    this.dropdownTarget.hidden = !this.dropdownTarget.hidden;
  }

  show() {
    if (this.mobilityValue === "offboarding") {
      this._show(this.endDatePickerTarget);
      this.dropdownTarget.hidden = true;
      setTimeout(() => {
        this.pickingDateValue = true;
      }, 400);
    } else {
      this.dropdownTarget.hidden = false;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.endDateTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.endDateTarget);
    }

    if (
      this.pickingDateValue ||
      !this.endDateContainerTarget.contains(event.target)
    ) {
      this.pickingDateValue = false;
      this._hide(this.endDatePickerTarget);
      this._workeloBlur(this.endDateTarget);
    }
  }

  pickNone(event) {
    event.preventDefault();

    this.endDateTarget.value = "";
    this.dropdownTarget.hidden = true;
    this.isPristineEndDateValue = true;
    this._workeloBlur(this.endDateTarget);
  }

  pickMonths(event) {
    event.preventDefault();

    const endDateElement = event.target.closest(".input-select__option");
    const months = parseInt(endDateElement.dataset.value, 10);
    const startDate = this.startDateTarget.value;

    if (startDate) {
      const endDate = moment(startDate, "DD/MM/YYYY")
        .add(months, "months")
        .format("DD/MM/YYYY");

      this._changeEndDate(endDate);
    }
    this.dropdownTarget.hidden = true;
    this.isPristineEndDateValue = true;
    this._workeloBlur(this.endDateTarget);
  }

  pickDate(event) {
    event.preventDefault();

    this._show(this.endDatePickerTarget);
    this.dropdownTarget.hidden = true;
    setTimeout(() => {
      this.pickingDateValue = true;
    }, 400);
  }

  resetStartDate() {
    if (!this.isPristineStartDateValue) {
      this.startDateTarget.value = this.defaultStartDateValue;
    }
  }

  reset() {
    if (!this.isPristineEndDateValue) {
      this.endDateTarget.value = this.defaultEndDateValue;
    }
  }

  touchStartDate() {
    if (this._isDate(this.startDateTarget.value)) {
      this.isPristineStartDateValue = true;
    } else {
      this.isPristineStartDateValue = false;
    }
  }

  changeStartDate() {
    if (this._isDate(this.startDateTarget.value)) {
      this._changeStartDate(this.startDateTarget.value);
    }
  }

  touch() {
    if (this._isDate(this.endDateTarget.value)) {
      this.isPristineEndDateValue = true;
    } else {
      this.isPristineEndDateValue = false;
    }
  }

  change() {
    if (this._isDate(this.endDateTarget.value)) {
      this._changeEndDate(this.endDateTarget.value);
    }
  }

  hideModalIfOutside(event) {
    if (
      this.modalContainerTarget.contains(event.target) &&
      !this.modalContentTarget.contains(event.target)
    ) {
      this._hideModal();
    }
  }

  cancelShiftDates(event) {
    event.preventDefault();

    this._hideModal();
  }

  hideModal(event) {
    event.preventDefault();

    this._hideModal();
  }

  confirmShiftDates(event) {
    event.preventDefault();

    track("Edit journey date", {
      mobility: this.mobilityValue,
      employee_id: this.employeeIdValue,
      date_type: this.oldStartDateValue ? "start_date" : "end_date",
      details: moment(this.newStartDateValue || this.newEndDateValue, "DD/MM/YYYY").format("YYYY-MM-DD"),
      "keep_action_date?": this.notShiftDatesTarget.checked,
      "notify_stakeholders?": this.notifyShiftTarget.checked,
    })

    fetch(this.shiftDatesUrlValue, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "x-csrf-token": csrfToken(),
      },
      body: JSON.stringify({
        shift_dates: {
          field: this.oldStartDateValue ? "start_date" : "end_date",
          old_value: this.oldStartDateValue || this.oldEndDateValue,
          new_value: this.newStartDateValue || this.newEndDateValue,
          shift_dates: !this.notShiftDatesTarget.checked,
          notify: this.notifyShiftTarget.checked,
        },
      }),
    }).then(() => {
      window.location.reload();
    });
  }

  _isDate(string) {
    return /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,5}$/.test(string || "");
  }

  _show(datetimepickerTarget) {
    if (!datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("show");
      $(datetimepickerTarget)
        .find(".day.active")
        .on("click", () => {
          $(datetimepickerTarget).trigger("change.datetimepicker", {
            date: $(datetimepickerTarget).datetimepicker("date"),
          });
        });
    }
  }

  _hide(datetimepickerTarget) {
    if (datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("hide");
    }
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }

  _changeStartDate(newValue) {
    if (newValue === this.startDateTarget.value) {
      return;
    } else if (this.shiftDatesValue && this.defaultStartDateValue !== newValue) {
      this.oldStartDateValue = this.defaultStartDateValue;
      this.newStartDateValue = newValue;
      this.oldEndDateValue = "";
      this.newEndDateValue = "";
      this.shiftStartDateTitleTarget.hidden = false;
      this.shiftEndDateTitleTarget.hidden = true;
      this.shiftStartDateDescriptionTarget.hidden = false;
      this.shiftEndDateDescriptionTarget.hidden = true;
      this.modalContainerTarget.hidden = false;
      this.modalContentTarget.hidden = false;
      this.startDateTarget.value = this.defaultStartDateValue;
    } else {
      this.startDateTarget.value = newValue;

      track("Edit journey date", {
        mobility: this.mobilityValue,
        employee_id: this.employeeIdValue,
        date_type: "start_date",
        details: moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    }

    this.isPristineStartDateValue = true;
  }

  _changeEndDate(newValue) {
    if (newValue === this.endDateTarget.value) {
      return;
    } else if (this.shiftDatesValue && this.defaultEndDateValue !== newValue) {
      this.oldStartDateValue = "";
      this.newStartDateValue = "";
      this.oldEndDateValue = this.defaultEndDateValue;
      this.newEndDateValue = newValue;
      this.shiftStartDateTitleTarget.hidden = true;
      this.shiftEndDateTitleTarget.hidden = false;
      this.shiftStartDateDescriptionTarget.hidden = true;
      this.shiftEndDateDescriptionTarget.hidden = false;
      this.modalContainerTarget.hidden = false;
      this.modalContentTarget.hidden = false;
      this.endDateTarget.value = this.defaultEndDateValue;
    } else {
      this.endDateTarget.value = newValue;

      track("Edit journey date", {
        mobility: this.mobilityValue,
        employee_id: this.employeeIdValue,
        date_type: "end_date",
        details: moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    }

    this.isPristineEndDateValue = true;
  }

  _hideModal() {
    $(this.startDatePickerTarget).datetimepicker(
      "date",
      this.startDateTarget.value,
    );
    $(this.endDatePickerTarget).datetimepicker(
      "date",
      this.endDateTarget.value,
    );
    this.oldStartDateValue = "";
    this.newStartDateValue = "";
    this.oldEndDateValue = "";
    this.newEndDateValue = "";
    this.shiftStartDateTitleTarget.hidden = true;
    this.shiftEndDateTitleTarget.hidden = true;
    this.shiftStartDateDescriptionTarget.hidden = true;
    this.shiftEndDateDescriptionTarget.hidden = true;
    this.modalContainerTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.notShiftDatesTarget.checked = false;
    this.notifyShiftTarget.checked = false;
  }
}
