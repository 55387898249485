import { Controller } from "@hotwired/stimulus"
import { flash } from '../../../components/flashes';

export default class extends Controller {
  copyWithFlash(event) {
    event.preventDefault();
    let text = this.element.dataset.copyText;
    this._copy(text);
    let notificationText = this.element.dataset.notificationText;
    flash(notificationText);
  }

  copyCurrentUrlWithFlash(event) {
    event.preventDefault();
    let text = window.location.href;
    this._copy(text);
    let notificationText = this.element.dataset.notificationText;
    flash(notificationText);
  }

  _copy(text) {
    // Create a temporary input
    const input = document.createElement('input');
    input.type = 'text';
    input.value = text;
    input.id = 'clipboard-input';
    this.element.parentElement.append(input);

    // Select and copy value
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("copy");

    // Remove the temporary input
    input.remove();
  }
}

