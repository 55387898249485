import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";

const HEADER_HEIGHT = 82;

export default class extends Controller {
  static targets = [
    "actions",
    "actionsCount",
    "dateTemplate",
    "actionTemplate",
    "weekTemplate",
  ];

  static values = {
    actionsUrl: String,
    trackId: String,
    actions: Array,
  };

  connect() {
    $(window).on("change-actor", (_, data) => {
      this.actionsValueChanged();
    });

    $(window).on("fetch-actions", async (_, data) => {
      await this.fetchActions();

      if (data?.action) {
        const { id, type } = data.action;

        const idSelector = `[data-desktop--actor--track--action-id-value="${id}"]`;
        const typeSelector = `[data-desktop--actor--track--action-type-value="${type}"]`;
        const actionElement = this.element.querySelector(
          `.js-action${idSelector}${typeSelector}`,
        );

        if (actionElement) {
          $("#page-content").scrollTop(actionElement.offsetTop - HEADER_HEIGHT);
        }
      }
    });

    $(window).trigger("fetched-actions", { actions: this.actionsValue });
  }

  async fetchActions() {
    const response = await fetch(this.actionsUrlValue);
    const json = await response.json();
    this.actionsValue = json;
    $(window).trigger("fetched-actions", { actions: this.actionsValue });
  }

  actionsValueChanged() {
    this.actionsTarget.innerHTML = this._displayedActions()
      .map(({ week, actions_grouped }) => {
        const weekWithDatesHtml = {
          ...week,
          datesHtml: actions_grouped
            .map(({ date, actions }) => {
              const dateWithActionsHtml = {
                ...date,
                actionsHtml: actions
                  .map((action) => {
                    let html = this.actionTemplateTarget.innerHTML;
                    html = replaceNames(html, action["actors_full_names"]);

                    return Object.entries(action).reduce(
                      (result, [key, value]) =>
                        result.replaceAll(`{{${key}}}`, escapeHtml(value)),
                      html,
                    );
                  })
                  .join(""),
              };

              return Object.entries(dateWithActionsHtml).reduce(
                (result, [key, value]) =>
                  result.replaceAll(
                    `{{${key}}}`,
                    key === "actionsHtml" ? value : escapeHtml(value),
                  ),
                this.dateTemplateTarget.innerHTML,
              );
            })
            .join(""),
        };

        return Object.entries(weekWithDatesHtml).reduce(
          (result, [key, value]) =>
            result.replaceAll(
              `{{${key}}}`,
              key === "datesHtml" ? value : escapeHtml(value),
            ),
          this.weekTemplateTarget.innerHTML,
        );
      })
      .join("");

    this.actionsCountTarget.innerHTML = this._translate("actions_to_do", {
      count: this._actions().length,
    });

    setTimeout(() => {
      const event = new Event("dragdrop.init");
      document.dispatchEvent(event);
    }, 200);
  }

  _displayedActions() {
    if (this.element.dataset.actorId == "all") {
      return this.actionsValue;
    } else {
      return this.actionsValue
        .map(({ week, actions_grouped }) => {
          return {
            week,
            actions_grouped: actions_grouped
              .map(({ date, actions }) => {
                return {
                  date,
                  actions: actions
                    .filter((action) =>
                      !this.element.dataset.actorId
                        ? !action.actor_ids
                        : action.actor_ids?.includes(
                            Number(this.element.dataset.actorId),
                          ),
                    )
                    .map((action) => {
                      return { ...action, actors_full_names_hidden: "hidden" };
                    }),
                };
              })
              .filter(({ date, actions }) => actions.length),
          };
        })
        .filter(({ week, actions_grouped }) => actions_grouped.length);
    }
  }

  _actions() {
    return Array.from(
      new Set(
        this._displayedActions()
          .flatMap((week_group) => week_group.actions_grouped)
          .flatMap((day_group) => day_group.actions),
      ),
    );
  }

  _translate(key, params) {
    const subKey = { 0: "zero", 1: "one" }[params.count] || "other";
    return JSON.parse(this.element.dataset.translations)[key][
      subKey
    ].replaceAll("%{count}", params.count);
  }
}

function replaceNames(html, full_names) {
  if (!html) return;

  const wrappedNames = full_names
    .map((full_name) => {
      return `
      <span {{actors_full_names_hidden}} class="padded-lateral-8px padded-vertical-4px border-radius-16px bg-primary-200 body-small-emphasis text-primary-700">
        ${full_name}
      </span>
    `;
    })
    .join("");

  return html.replace("{{actors_full_names}}", wrappedNames);
}
