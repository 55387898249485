import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['count', 'text']
  static values = { count: Number, wording: Object }

  connect() {
    this.observer = new MutationObserver(this.listenCountMutation.bind(this));
    this.observer.observe(this.element, { attributes: true });
  }

  listenCountMutation(mutationsList) {
    for(let mutation of mutationsList) {
      let count = mutation.target.dataset['utils-CountersCountValue']

      if ((mutation.attributeName == 'data-utils--counters-count-value') && (count)) {
        this.updateCount();
        this.updateWording();
      }
    }
  }

  updateCount() {
    this.countTarget.innerText = this.countValue;
  }

  updateWording() {
    this.textTarget.innerText = this.defineWording();
  }

  defineWording() {
    let count = parseInt(this.countValue);
    switch (count) {
      case 0:
        return this.wordingValue.zero;
      case 1:
        return this.wordingValue.one;
      default:
        return this.wordingValue.other;
    }
  }
}
