import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static values = {
    url: String,
  };

  translations = JSON.parse(this.element.dataset.translations);

  async show(event) {
    const resourceType = event.detail.resourceType;
    const resourceId = event.detail.resourceId;

    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-csrf-token": csrfToken(),
      },
      body: JSON.stringify({
        resource_type: event.detail.resourceType,
        resource_id: event.detail.resourceId,
      }),
    });

    const action = await response.json();

    flash(this.translations.action_added, "notice");

    $(window).trigger("fetch-actions", { action });
  }
}
