import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["picker", "text", "button", "hiddenInput"];
  static values = { immediate: String };

  connect() {
    $(this.pickerTarget).datetimepicker({
      inline: true,
      format: "DD/MM/YYYY",
      minDate: moment(),
    });

    this._hide(this.pickerTarget);

    $(this.pickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;

      this.hiddenInputTarget.value = date.format("DD/MM/YYYY");

      if (date.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")) {
        this.textTarget.innerText = this.immediateValue;
      } else {
        this.textTarget.innerText = date.format("D MMMM YYYY");
      }

      this._hide(this.pickerTarget);
    });
  }

  toggle(event) {
    event.preventDefault();

    this._toggle(this.pickerTarget);
  }

  hideIfOutside(event) {
    if (
      !this.pickerTarget.contains(event.target) &&
      !this.buttonTarget.contains(event.target)
    ) {
      this._hide(this.pickerTarget);
    }
  }

  _toggle(datetimepickerTarget) {
    if (datetimepickerTarget.querySelector(".datepicker")) {
      this._hide(datetimepickerTarget);
    } else {
      this._show(datetimepickerTarget);
    }
  }

  _show(datetimepickerTarget) {
    if (!datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("show");
      $(datetimepickerTarget)
        .find(".day.active")
        .on("click", () => {
          $(datetimepickerTarget).trigger("change.datetimepicker", {
            date: $(datetimepickerTarget).datetimepicker("date"),
          });
        });
    }
  }

  _hide(datetimepickerTarget) {
    if (datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("hide");
    }
  }
}
